import { render, staticRenderFns } from "./LookDetailDialog.vue?vue&type=template&id=35281111&scoped=true"
import script from "./LookDetailDialog.vue?vue&type=script&lang=js"
export * from "./LookDetailDialog.vue?vue&type=script&lang=js"
import style0 from "./LookDetailDialog.vue?vue&type=style&index=0&id=35281111&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35281111",
  null
  
)

export default component.exports