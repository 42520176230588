var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{staticClass:"open-ads",attrs:{"footer":null,"close-on-press-escape":false,"append-to-body":"","title":null,"show-close":false,"visible":_vm.visible,"close-on-click-modal":false,"width":"65vw","center":"","custom-class":"custom-open-ads"}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"img-wrapper"},[_c('div',{staticClass:"close-btn"},[_vm._v(" 金牌供应商 "),_c('span',{staticClass:"small-font"},[_vm._v(" "+_vm._s(_vm.time ? _vm.time + "s" : "")+" ")])]),_c('el-image',{staticClass:"img",attrs:{"src":_vm.showAdsInfo.url,"alt":""},on:{"click":_vm.handleDetail,"load":_vm.handleImgLoad,"error":_vm.handleImgLoadError}})],1)])]),_c('el-drawer',{attrs:{"title":"详情","visible":_vm.pdfVisible,"withHeader":false,"size":"50%","append-to-body":""},on:{"update:visible":function($event){_vm.pdfVisible=$event}}},[_c('div',{staticClass:"bar"},[_c('DialogFooter',{staticStyle:{"gap":"10px","justify-content":"flex-start"},attrs:{"showTab":"NB","confirmOptions":{
                    size: 'small',
                },"cancelOptions":{
                    size: 'small',
                },"cancelText":"下一个","confirmText":"上一个","top":"0"},on:{"confirm":function($event){return _vm.handlePre()},"cancel":function($event){return _vm.handleNext()}}}),_c('DialogFooter',{staticStyle:{"gap":"10px","justify-content":"flex-end"},attrs:{"showTab":"NB","confirmOptions":{
                    size: 'small',
                },"cancelOptions":{
                    size: 'small',
                },"cancelText":"关闭","confirmText":"网页显示","top":"0"},on:{"confirm":function($event){return _vm.goToDetail()},"cancel":_vm.handleDetailClose}})],1),_c('PdfView',{staticStyle:{"height":"calc(100% - 50px)"},attrs:{"url":_vm.adsList[_vm.curShowIndex].pdfUrl}})],1),(!_vm.visible && !_vm.pdfVisible && _vm.smallVisible)?_c('div',{staticClass:"small-window"},[_c('div',{staticClass:"small-window-container"},[_c('div',{staticClass:"title"},[_vm._v(" 金牌供应商 "),_c('span',{staticClass:"close-btn",on:{"click":_vm.handleCloseSmallWindow}},[_vm._v(" X ")])]),_c('img',{staticClass:"img",attrs:{"src":_vm.showAdsInfo.url,"alt":""},on:{"click":_vm.handleDetail}})])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }