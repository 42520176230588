<template>
    <div>
      <el-dialog
          title="VGM操作历记录"
          :visible.sync="dialogVisible"
          width="50%"
          append-to-body
          center
      >
        <p class="tip_span">
          提单号：{{ vgmItem ? vgmItem.billNo : '' }}
        </p>
        <el-table :data="Data" style="width: 100%" stripe border
                  max-height="500"
                  ref="tableList"
                  @expand-change="handleExpandChange"
                  :header-cell-style="{background: '#d4d9e1',color: '#606278'}"
        >
          <el-table-column type="expand" v-if="checkPer(['shManifest:rocket'])">
            <template slot-scope="props">
              <div class="the_content">
                <BaseFormModule :item="detail.bizVgmEntity" ref="baseForm" :is-look="true"></BaseFormModule>
                <div class="the_title">集装箱信息</div>
                <VGMContainerTable ref="ManifestGoodsTable" :item="detail.bizVgmContainerEntityList" :is-look="true"></VGMContainerTable>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="operator"
              label="操作类型"
          ></el-table-column>
          <el-table-column
              prop="userName"
              width="100"
              label="操作人员"
          ></el-table-column>
          <el-table-column prop="createTime" label="时间" width="160">
          </el-table-column>
          <!--        <slot name="todo">-->
          <!--          <el-table-column fixed="right" label="操作" width="100">-->
          <!--            <template slot-scope="scope">-->
          <!--              <span class="operator_span">查看数据</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <!--        </slot>-->
        </el-table>
        <span slot="footer" class="dialog-footer">
                  <el-button @click="onClose()">
                      关 闭
                  </el-button>
              </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import {getVgmHistory} from "@/api/vgm";
  import BaseFormModule from "@/views/VGM/components/baseInfo/BaseFormModule.vue";
  import VGMContainerTable from "@/views/VGM/components/container/VGMContainerTable.vue";
  
  export default {
    components: { VGMContainerTable, BaseFormModule},
    data() {
      return {
        dataLoading: false,
        dialogVisible: false,
        vgmItem: '',
        detail: {head: {}, contacts: [], goods: []},
        Data: [],
        // 展开数组
        expandRows: [],
      };
    },
    methods: {
      init(item) {
        this.dialogVisible = true;
        this.vgmItem = item;
        this.getHistoryData();
      },
      getHistoryData() {
        this.dataLoading = true;
        console.log("vgmItem:"+JSON.stringify(this.vgmItem));
        getVgmHistory({vgmId: this.vgmItem.id})
            .then((res) => {
              if (res.data.status) {
                this.Data = res.data.list;
              }
  
            })
            .finally(() => {
              this.dataLoading = false;
            });
      },
      //将数据内容转化成json对象
      content2Json(content) {
        this.detail = JSON.parse(content);
      },
      onClose() {
        this.billItem = null;
        this.Data = [];
        this.dialogVisible = false;
      },
      // 展开改变触发函数,只展开一行
      handleExpandChange(row, expandedRows) {
        if (expandedRows.length > 1) {
          this.$refs.tableList.toggleRowExpansion(expandedRows[0])
        }
        this.$nextTick(() => {
          this.content2Json(row['content']);
        })
        this.$forceUpdate();
      },
    },
  };
  </script>
  
  <style lang="stylus" scoped>
  .tip_span {
    text-align: center;
    color: var(--RED-F21137);
    margin: 0 0 1rem 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 2;
  }
  
  .operator_span {
    cursor: pointer;
    text-align: center;
    color: var(--BLUE-409eff);
  }
  
  .demo-table-expand {
    font-size: 0;
  }
  
  .demo-table-expand label {
    width: 100%;
    color: #99a9bf;
  }
  
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    padding: 0 1rem;
    line-height: 1.5;
    width: 100%;
  }
  
  .the_title {
    user-select: none;
    cursor: pointer;
    padding: 0.6rem 0;
    font-size: 16px;
    text-align: center;
    background: var(--GRAY-dcdcdc);
    margin-bottom: 1rem;
  }
  </style>
  