<template>
  <el-container id="the_button" v-loading="dataLoading" element-loading-text="数据加载中"
                element-loading-background="rgba(255, 255, 255, 0.5)">
    <div class="header" v-if="!isManifestSub">
      <div class="title">
        <!--        <span>{{ title }}VGM</span>-->
        <span class="user_remark">备注：
          <span @click="addRemark"><i
              class="el-icon-edit"></i>{{ detail.bizVgmEntity.remark ? detail.bizVgmEntity.remark : '添加备注' }}</span>
        </span>
      </div>
      <div class="the_button">
        <el-button type="primary" @click="save2Wait()" size="small" :loading="saveLoading">保存到待发送</el-button>
        <el-button type="primary" plain @click="save2Draft()" size="small">保存到草稿箱</el-button>
        <el-button plain size="small" @click="close">关闭</el-button>
      </div>
    </div>
    <div class="vgm_div" v-if="isManifestSub&&detail.bizVgmContainerEntityList.length===0" :key="1">
      <el-button type="text" @click="autoVGM" :disabled="isLook"><i class="el-icon-warning"></i>点击自动生成VGM
      </el-button>
    </div>
    <div v-else :key="2">
      <BaseFormModule :item="baseForm" ref="baseForm" :ships-company="shipsCompany"
                      :is-manifest-sub="isManifestSub"
                      :wharf-list="wharfList"
                      :is-look="isLook"
                      :get-wharf-info-loading="getWharfInfoLoading"
                      @autoVGM="autoVGM"
                      @removeVGM="removeVGM"
                      @wharfSure="wharfSure"
                      @changeTableShowProps="changeTableShowProps"
      ></BaseFormModule>
      <div class="the_title" v-if="!isManifestSub">集装箱信息</div>
      <VGMContainerTable ref="VGMContainerTable" :item="containerList" :not-show-table-props="notShowTableProps"
                         :is-manifest-sub="isManifestSub"
                         :is-look="isLook"
                         @itemsDeleted="refreshContainerList"></VGMContainerTable>
    </div>
    <!-- 弹窗, 修改备注 -->
    <remark-box-dialog
        :show-tab="showTab"
        ref="RemarkBoxDialog"
        @refreshDataList="showRemark"
    ></remark-box-dialog>
    <!--    <GetWharfDialog ref="GetWharfDialog" @changeWharf="changeWharf" @sure="save2Draft(1)"></GetWharfDialog>-->

  </el-container>
</template>

<script>
import {
  onUpdate,
  onSave,
  getDetailById, getShWharfList,
} from "@/api/vgm";
import {
  checkVesselsVoyageInfo,
  getDetailById as getManifestDetailById,
} from "@/api/shanghaiManifest";
import DialogVue from "@/components/common/Dialog.vue";
import VGMContainerTable from "@/views/VGM/components/container/VGMContainerTable.vue";
import BaseFormModule from "@/views/VGM/components/baseInfo/BaseFormModule.vue";
import {getAllShipCompanyData} from "@/api/shanghaiManifest";
import bus from "@/utils/bus";
import RemarkBoxDialog from "@/views/ShanghaiManifest/components/ManifestRemarkDialog.vue";
import GetWharfDialog from "@/views/VGM/components/GetWharfDialog.vue";

export default {
  props: {
    isManifestSub: {//true代表作为舱单编辑页面组件，需要隐藏重复的输入项
      type: Boolean,
      default: false
    },
    isLook: {//true代表作为舱单编辑页面组件且舱单已发送，VGM只能查看不能创建修改
      type: Boolean,
      default: false
    },
    item: {//列表传入的item
      type: Object,
      default: () => {
      }
    },
    index: {
      type: String,
      default: "0", //default 默认值，父组件不传值就显示默认
    },
    list: {
      type: Object,
      default: () => {
      },
    },
  },
  watch: {
    shipsCompany(newVal) {
      // console.log('shipsCompany==', newVal)
      if (this.baseForm && this.baseForm.shipCompany) {
        let fiItem = newVal.filter(item => item.value === this.baseForm.shipCompany)
        //包含在列表里代表不显示
        let props = [];
        if (fiItem[0]?.isVgmAddress === 0) {
          props.push('vgmAddress');
        }
        if (fiItem[0]?.isVgmWeightDetail === 0) {
          props.push('vgmNetWeight');
          props.push('vgmTareWeight');
        }
        this.notShowTableProps = props;
      }
    },
  },
  components: {
    GetWharfDialog,
    RemarkBoxDialog,
    BaseFormModule,
    VGMContainerTable,
    DialogVue,
  },
  computed: {},
  data() {
    return {
      showTab: "VGM",
      title: '修改',
      shipsCompany: [],
      wharfList: [],//码头列表
      saveLoading: false,
      getWharfInfoLoading: false,
      dataLoading: false,
      notShowTableProps: ['vgmAddress', 'vgmNetWeight', 'vgmTareWeight'],
      detail: {
        bizVgmEntity: {},
        bizVgmContainerEntityList: [],
      },
      baseForm: {},
      //集装箱明细
      containerList: [],
      manifestDataId: '',//vgm对象的舱单id，从舱单列表生成VGM用来绑定两者
    };
  },
  methods: {
    init() {
      // this.getWharfList();
      const params = this.$route.params
      bus.$on("refreshVGM", (data) => {
        this.detail = data;
        this.$nextTick(() => {
          this.baseForm = this.detail.bizVgmEntity;
          this.containerList = this.detail.bizVgmContainerEntityList;
          this.$forceUpdate();
        })
      });
      bus.$on("refreshVGMShipCompany", (data) => {
        this.detail.bizVgmEntity.shipCompany = data;
        this.$nextTick(() => {
          this.baseForm = this.detail.bizVgmEntity;
          this.containerList = this.detail.bizVgmContainerEntityList;
        })
      });
      if (!this.isManifestSub && params && params.id) {
        console.log('' + JSON.stringify(params));
        //如果是复制操作，则调用复制函数
        let isCopy = false;
        this.title = params.title;
        if (params.copy) {
          this.title = '复制';
          isCopy = true;
        }
        this.getDetail(params.id, isCopy)
      } else if (params && params.manifestDataId) {
        this.title = params.title;
        this.manifestDataId = params.manifestDataId;
        this.getManifestDetail(this.manifestDataId);
      }
      this.searchShipCompany();
    },
    searchShipCompany(keyword = '') {
      const data = {keyWord: keyword.trim()}
      getAllShipCompanyData(data).then(({data}) => {
        this.shipsCompany = data.data.map(x => {
          return {
            id: x.id,
            label: x.codeName,
            value: x.code + '—' + x.simpleName,
            vgmRemark: x.vgmRemark,
            isVgmSoNo: x.isVgmSoNo,
            isVgmWeightDetail: x.isVgmWeightDetail,
            isVgmDate: x.isVgmDate,
            isVgmAddress: x.isVgmAddress,
            disabled: x.isUsed !== 1,
          }
        });
      });
    },
    getDetail(id, isCopy = false) {
      getDetailById(id)
          .then((response) => {
            this.detail = response.data.data;
            this.baseForm = this.detail.bizVgmEntity;
            if (isCopy) {
              this.baseForm.id = null;
            }
            this.containerList = this.detail.bizVgmContainerEntityList
          }).finally(() => {
        this.searchShipCompany();
      })
    },
    getManifestDetail(id) {
      this.dataLoading = true;
      getManifestDetailById({id: id})
          .then((response) => {
            const manifestDetail = response.data.data;
            let vgmDetail = {};
            let containerList = manifestDetail.goods.map(item => {
              return {
                ctnNo: item.ctnno,
                vgmMethod: 'SM2',
                vgmNetWeight: '',
                vgmTareWeight: '',
                vgmGrossWeight: '',
                vgmLocation: manifestDetail.contacts.filter(x => x.type === 0)[0].name,
                vgmAddress: '',
                vgmSign: '',
              }
            });
            const map = new Map();
            let ctnList = containerList.filter(v => !map.has(v.ctnNo) && map.set(v.ctnNo, v));
            this.$nextTick(() => {
              vgmDetail.bizVgmEntity = {
                billNo: manifestDetail.head.billno, //提单号
                vessel: manifestDetail.head.vesselname, //船公司
                voyage: manifestDetail.head.voyageno, //船公司
                shipCompany: manifestDetail.head.scompany, //船公司
                portType: "1", //港口，0：宁波，1：上海
                ctnSocMark: "N", //货主箱标志，Y=货主箱N=非货主箱
                isSendSync: 0,
              }
              vgmDetail.bizVgmContainerEntityList = ctnList;
            })
            this.detail = vgmDetail;
            this.$nextTick(() => {
              this.baseForm = this.detail.bizVgmEntity;
              this.containerList = this.detail.bizVgmContainerEntityList;
            })
          }).finally(() => {
        this.dataLoading = false;
      })
    },

    //status，0：草稿箱，1：待发送，2：已发送
    save2Draft(status = 0) {
      // console.log("base form===", this.$refs.baseForm.form);
      this.baseForm = this.$refs.baseForm.form;
      this.baseForm.completionstatus = status;
      this.baseForm.manifestDataId = this.manifestDataId;
      //如果是企业身份需要传企业ID和名称
      this.baseForm.companyId = this.$store.getters.cid;
      this.baseForm.companyName = this.$store.getters.loginId;
      this.detail = {
        bizVgmEntity: this.$refs.baseForm.form,
        bizVgmContainerEntityList: this.$refs.VGMContainerTable.form.containerList
      }
      const request = this.baseForm.id ? onUpdate : onSave
      request(this.detail).then((response) => {
        if (response.data.status) {
          this.$message({
            type: "success",
            message: response.data.data,
          });
          this.$router.back();
        } else {
          this.$message({
            type: "error",
            message: response.data.data,
          });
        }
      }).finally(() => {
        this.saveLoading = false;
      });
    },
    getVGMDetail() {
      if (this.$refs.baseForm && this.$refs.VGMContainerTable) {
        this.detail = {
          bizVgmEntity: this.$refs.baseForm.form,
          bizVgmContainerEntityList: this.$refs.VGMContainerTable.form.containerList
        }
        return this.detail;
      } else {
        return null;
      }
    },
    save2Wait() {
      this.saveLoading = true;
      let list = [];
      list.push(
          this.$refs.baseForm.validate(),
          this.$refs.VGMContainerTable.validate(),
      );
      Promise.all(list)
          .then(() => {
            this.checkWharf();
          }).catch((err) => {
        this.saveLoading = false;
        this.$message({type: 'error', message: err})
      })
    },
    checkWharf() {
      this.$refs.baseForm.checkWharf().then(() => {
        this.save2Draft(1);
      }).catch((err) => {
        this.saveLoading = false;
        this.$message({type: 'error', message: err})
      });
    },
    close() {
      this.$router.back();
    },
    refreshContainerList(newList) {
      this.containerList = newList
    },
    syncNotice(item) {
      const data = JSON.parse(JSON.stringify(item));
      this.$refs.informersForm.syncByRevice(data)
    },
    //对应船公司显示不同的列表填写内容
    changeTableShowProps(prop = []) {
      this.notShowTableProps = prop;
    },
    autoVGM() {
      this.$emit("autoVGM");
    },
    removeVGM() {
      this.detail = {
        bizVgmEntity: {},
        bizVgmContainerEntityList: [],
      }
      this.$nextTick(() => {
        this.baseForm = this.detail.bizVgmEntity;
        this.containerList = this.detail.bizVgmContainerEntityList;
      })
    },
    //添加备注
    addRemark() {
      this.$nextTick(() => {
        this.$refs.RemarkBoxDialog.init({
          id: this.detail.bizVgmEntity.id,
          blno: this.detail.bizVgmEntity.billNo,
          remark: this.detail.bizVgmEntity.remark
        });
      });
    },
    showRemark(remark) {
      this.detail.bizVgmEntity.remark = remark;
    },
    /**
     * 查询船名航次是否存在
     * @returns {boolean}
     * type:0：查码头，1：校验码头-不一致弹窗提示
     */
    getWharfInfo(type = 0) {
      console.log("type===", type)
      if (!this.$refs.baseForm.form.vessel || !this.$refs.baseForm.form.voyage) {
        this.$message.error("请输入船名和航次")
        return false;
      } else if (this.$refs.baseForm.form.vessel.includes('V.') || this.$refs.baseForm.form.voyage.includes('v.')) {
        this.$message.error("航次不能有V.")
        return false;
      }
      const param = {
        vessels: this.$refs.baseForm.form.vessel,
        voyage: this.$refs.baseForm.form.voyage,
        wharf: this.$refs.baseForm.form.wharf,
        wharfCode: this.$refs.baseForm.form.wharfCode,
      }
      this.getWharfInfoLoading = true;
      checkVesselsVoyageInfo(param).then((res) => {
        if (res.data.status) {
          if (type === 0) {
            this.$refs.GetWharfDialog.init(param, res.data.data);
          } else {
            if (res.data.data.length === 1 && res.data.data[0].wharfCode.toUpperCase() === this.$refs.baseForm.form.wharfCode.toUpperCase()) {
              this.save2Draft(1);
            } else {
              this.$refs.GetWharfDialog.init(param, res.data.data, type);
            }
          }
        }
      }).finally(() => {
        this.getWharfInfoLoading = false;
      })
    },
    changeWharf(entity) {
      let wharfCode = entity.wharfCode.toLowerCase()
      const matchedWharf = this.wharfList.find(item => item.otherCode === wharfCode);
      this.$refs.baseForm.form.wharfCode = matchedWharf && matchedWharf.value;
      this.$refs.baseForm.form.wharf = matchedWharf && matchedWharf.label;
      this.$forceUpdate();
    },
    wharfSure(status=1) {
      if (this.isManifestSub) {
        this.$emit("wharfSure",status);
      } else {
        this.save2Draft(1)
      }
    },
    //码头列表
    getWharfList() {
      getShWharfList().then(({data}) => {
        this.wharfList = data.data.list.map(x => {
          return {
            id: x.id,
            label: x.nickName ? `（${x.nickName}）${x.name}` : x.name,
            value: x.code,
            otherCode: x.otherCode
          }
        });
      });
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    bus.$off('refreshVGM');
    bus.$off('refreshVGMShipCompany');
  },
  //自定义指令
  directives: {
    'el-select-loadmore': (el, binding) => {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
      if (SELECTWRAP_DOM) {
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,
           *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) binding.value();
        });
      }
    },
  }
};
</script>

<style lang="stylus" scoped>
#the_button {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 1rem;
  box-shadow: 10px 6px 10px 3px var(--GRAY-dcdcdc);
}

.title {
  font-weight: bold;
  font-size: 16px;
}

.dialog-footer {
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin: 30px auto 0;
}

.the_title {
  user-select: none;
  cursor: pointer;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin-bottom: 1rem;
}

.el-icon-arrow-down, .el-icon-arrow-up {
  cursor: pointer;
}

.vgm_div {
  display: flex;
  flex-direction: column;
  margin: 0 auto 2rem;

  i {
    margin: 0 0.5rem;
    color: var(--YELLOW-ed6a0c);
  }
}

.user_remark {
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}
</style>
