<template>
  <div>
    <el-form label-position="right" label-width="140px" :model="form" style="max-width: 460px"
             ref="ContactForm" :rules="formRules">
      <el-form-item label="简称：" prop="nickName">
        <el-input v-enterNextInput
                  v-model="form.nickName"
                  placeholder="请输入简称,方便您检索">
        </el-input>
      </el-form-item>
      <el-form-item label="企业名称：" prop="name">
        <el-input
            ref="Name"
            v-enterNextInput
            maxlength="70"
            show-word-limit
            v-model="form.name"
            @input="inputFilter2($event,'name')"
            placeholder="请输入内容,70个字符以内"/>
      </el-form-item>
      <el-form-item label="企业地址：" prop="address">
        <el-input
            ref="Address"
            v-enterNextInput
            maxlength="70"
            show-word-limit
            v-model="form.address"
            @input="inputFilter2($event,'address')"
            placeholder="请输入内容,70个字符以内"></el-input>
      </el-form-item>
      <el-form-item label="国家/地区代码：" prop="country">
        <el-select ref="Country" v-enterNextInput v-model="form.country" placeholder="请选择" clearable filterable
                   default-first-option>
          <el-option v-for="item in countryList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="企业代码：" prop="companyCode">
        <el-input
            ref="CompanyCode"
            v-enterNextInput
            v-model="form.companyCode"
            @input="inputFilter2($event,'companyCode')"
            placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：" prop="phone">
        <el-input ref="Phone" v-enterNextInput v-model="form.phone"
                  placeholder="请输入电话号码"/>
      </el-form-item>
      <el-form-item label="AEO代码（选填）">
        <el-input ref="AEOCode" v-model="form.aeoCode" placeholder="请输入内容"
                  @input="inputFilter2($event,'aeoCode')"></el-input>
      </el-form-item>
      <!--      <el-form-item>-->
      <!--        <el-button @click="closeDialog">取 消</el-button>-->
      <!--        <el-button type="primary" @click="save">确 定</el-button>-->
      <!--      </el-form-item>-->
    </el-form>
  </div>
</template>
<script>
import {getAllCountryCode, addRSN, updateRSN, insertOrUpdateRSN} from "@/api/shanghaiManifest";
import {setRightForrmat, setRightForrmatPlus, setRightForrmatSpace2} from "@/utils/validate";

export default {
  name: "AddManifestContactForm",
  props: {
    countryList: {
      type: Array,
      default: () => []
    },
    isShow: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {
      }
    },
    typeVal: {
      type: Object,
      default: () => {
        return {
          title: '',
          code: ''
        }
      }
    }
  },
  watch: {
    item(newVal, oldVal) {
      if (newVal) {
        console.log("newVal==",newVal)
        this.form = JSON.parse(JSON.stringify(newVal));
        this.$forceUpdate();
      } else {
        this.reset();
        this.$refs.ContactForm.clearValidate();
      }
    },
    typeVal(newVal, oldVal) {
      this.form.type = newVal.code
    },
  },
  data() {
    const validateSFT = (rule, value, callback) => {
      if (!(/(^[A-Z0-9#;$%&,./@()'"\- ]+)$/.test(value))) {
        callback(new Error('不能有中文，小写字母，全角字符和冒号'));
      }else if (value.toUpperCase().includes("TO ORDER")) {
        callback(new Error('不能有TO ORDER'));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      if (!(/(^[0-9,./()\- ]+)$/.test(value))) {
        callback(new Error('仅支持输入数字，半角符号（含空格）'));
      } else {
        callback();
      }
    };
    const validateCountry = (rule, value, callback) => {
      if (!(/(^[A-Z]{2}[\-]{1}[\u4e00-\u9fa5()-]+)$/.test(value))) {
        callback(new Error('国家代码格式：英文代码-国家名称'));
      } else {
        callback();
      }
    };
    return {
      form: {
        name: '',
        address: '',
        phone: '',
        email: '',
        fax: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        contactFax: '',
        companyCode: '',
        country: '',
        type: this.typeVal.code,
        nickName: '',
        aeoCode: ''
      },
      formRules: {
        name: [
          {required: true, message: '企业名称必填', trigger: 'change'},
          {min: 0, max: 70, message: '长度70个字符以内', trigger: 'blur'},
          {validator: validateSFT, trigger: 'blur'},
        ],
        address: [
          {required: true, message: '企业地址必填', trigger: 'change'},
          {min: 0, max: 70, message: '长度70个字符以内', trigger: 'blur'},
          {validator: validateSFT, trigger: 'blur'},
        ],
        country: [
          {required: true, message: '请选择', trigger: 'change'},
          {validator: validateCountry, trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '联系方式必填', trigger: 'change'},
          {validator: validatePhone, trigger: 'blur'},
        ]
      },

    }
  },
  methods: {
    init() {
      if (this.item && this.item.id) {
        this.form = JSON.parse(JSON.stringify(this.item));
      }
    },
    getAllCountryData() {
      getAllCountryCode().then(({data}) => {
        this.countryList = data.data.map(x => {
          return {
            id: x.id,
            label: x.code + '-' + x.name,
            value: x.code + '-' + x.name,
          }
        })
      })
    },
    inputFilter(val, key) {
      this.form[key] = setRightForrmat(val);
    },
    inputFilter2(val, key) {
      this.form[key] = setRightForrmatPlus(val);
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.ContactForm.validate((valid) => {
          if (valid) {
            resolve();
          } else {
            reject('请检查必填项')
          }
        })
      });
    },
    reset() {
      this.form = JSON.parse(JSON.stringify(
          {
            name: '',
            address: '',
            phone: '',
            email: '',
            fax: '',
            contactName: '',
            contactPhone: '',
            contactEmail: '',
            contactFax: '',
            companyCode: '',
            country: '',
            type: this.typeVal.code,
            nickName: '',
            aeoCode: ''
          }))
    },
    closeDialog() {
      this.reset();
      this.$emit('close');
    },
    save() {
      let request
      if (this.form.id) {
        request = updateRSN;
      } else {
        request = addRSN;
      }
      request(this.form).then(({data}) => {
        if (data.code === 0) {
          this.$message({type: 'success', message: '保存成功'})
        } else {
          this.$message({type: 'error', message: '保存失败'})
        }
      }).finally(() => {
        this.$emit('refresh')
        this.closeDialog()
      })
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style scoped lang="stylus">

</style>
