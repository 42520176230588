<template>
    <div style="min-width: 1200px">
        <div class="carousel">
            <el-carousel :autoplay="false">
                <el-carousel-item>
                    <div class="home_page_wrap">
                        <div class="home_page">
                            <el-image
                                :src="HomeBg"
                                style="height: 100%"
                            ></el-image>
                            <el-image
                                :src="HLJImage"
                                :preview-src-list="HLJPreView"
                                class="imgHei"
                            />
                            <el-image
                                :src="NBImage"
                                :preview-src-list="NBPreView"
                                class="imgNB"
                            />
                            <el-image
                                :src="KCImage"
                                :preview-src-list="KCPreView"
                                class="imgKuche"
                            />
                            <el-image
                                :src="SCImage"
                                :preview-src-list="SCPreView"
                                class="imgShache"
                            />
                            <el-image
                                :src="CHImage"
                                :preview-src-list="CHPreView"
                                class="imgCH"
                            />
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="swiper_first first_bg">
                        <el-image
                            :src="swiperFirstLeftImg"
                            class="swiper_first_lImg"
                        ></el-image>
                        <el-image
                            :src="swiperFirstRightImg"
                            class="swiper_first_lImg"
                            :preview-src-list="swiperImageList"
                        ></el-image>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="swiper_first second_bg">
                        <el-image
                            :src="swiperSecondLeftImg"
                            class="swiper_first_lImg"
                        ></el-image>
                        <div
                            style="width: 50%; position: relative; height: 100%"
                        >
                            <el-image
                                :src="swiperSecondRightImg"
                                class="ship_first_lImg"
                                :preview-src-list="swiperImageList"
                            ></el-image>
                            <el-image
                                :src="swiperSecondRightMap"
                                class="ship_second_map"
                                :preview-src-list="swiperImageList"
                            ></el-image>
                            <el-image
                                :src="swiperSecondRightIcon"
                                class="ship_second_icon"
                                :preview-src-list="swiperImageList"
                            ></el-image>
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="f1_order_bg">
                        <el-image
                            :src="f1OrderleftImg"
                            style="object-fit: contain"
                        />
                        <div
                            style="width: 50%; position: relative; height: 100%"
                        >
                            <el-image
                                :src="f1OrderFirstImg"
                                :preview-src-list="f1OrderImgList"
                                class="order_screen_first"
                            />
                            <el-image
                                :src="f1OrderSecondImg"
                                :preview-src-list="f1OrderImgList"
                                class="order_screen_second"
                            />
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="f1_wx_bg">
                        <el-image
                            :src="f1wxleftImg"
                            style="object-fit: contain"
                        />
                        <div
                            style="width: 50%; position: relative; height: 100%"
                        >
                            <el-image
                                :src="f1wxFirstImg"
                                :preview-src-list="f1wxImgList"
                                class="f1_screen_first"
                                style="height: 95%; top: 5%"
                            />
                            <el-image :src="f1wxCode" class="f1_code" />
                            <el-image
                                :src="f1wxSecondImg"
                                :preview-src-list="f1wxImgList"
                                class="f1_screen_second"
                            />
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="feature_module_wrap">
            <div class="feature_module">
                <h2 class="pro_title">
                    <el-image :src="preIcon" lazy></el-image>
                    <span style="padding: 0 15px">产品功能入口</span>
                    <el-image
                        :src="preIcon"
                        lazy
                        style="transform: rotate(180deg)"
                    ></el-image>
                </h2>
                <div class="duct_chunks">
                    <div
                        class="duct_item"
                        v-for="(item, index) in featureList"
                        :key="index"
                        @click="enterDetail(item)"
                    >
                        <el-image
                            lazy
                            :src="item.url"
                            fit="scale-down"
                            class="duct_icon animate__animated animate__zoomIn"
                        ></el-image>
                        <div class="right_view">
                            <h1
                                style="
                                    font-weight: 700;
                                    margin: 0;
                                "
                            >
                                {{ item.title }}
                            </h1>
                            <p>{{ item.detail }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="product_module_wrap">
            <div class="product_module">
                <h2 class="pro_title">
                    <el-image :src="preIcon" lazy></el-image>
                    <span style="padding: 0 15px">企业产品与服务</span>
                    <el-image
                        :src="preIcon"
                        lazy
                        style="transform: rotate(180deg)"
                    ></el-image>
                </h2>
                <div class="pro_contain">
                    <div
                        class="pro_chunk"
                        v-for="(item, index) in productList"
                        :key="index"
                        @click="enterProDetail(item.link)"
                    >
                        <div class="hide_bottom">
                            <div class="pro_head">
                                <el-image
                                    lazy
                                    :src="item.img"
                                    style="width: 100px; height: 100px"
                                ></el-image>
                                <h1 class="pro_head_title">
                                    {{ item.name }}
                                </h1>
                            </div>
                            <ul class="pro_explain_wrap">
                                <li
                                    v-for="(el, einx) in item.detail"
                                    :key="einx"
                                    class="pro_explain"
                                >
                                    <el-image
                                        lazy
                                        :src="detailPreIcon"
                                        style="
                                            width: 30px;
                                            height: 30px;
                                            margin-right: 10px;
                                            flex-shrink: 0;
                                        "
                                    ></el-image>
                                    <span
                                        style="
                                            padding-left: 20px;
                                            display: inline-block;
                                        "
                                    >
                                        {{ el }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div
                            class="hide_img"
                            :style="{
                                backgroundImage: 'url(' + item.hideImg + ')',
                                backgroundSize: '100% 100%',
                                backgroundRepeat: 'no-repeat',
                            }"
                        >
                            <ul class="hide_img_ul">
                                <li
                                    v-for="(hideItem, hinx) in item.hideDetail"
                                    :key="hinx"
                                >
                                    {{ hideItem }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="customs_module_wrap">
            <div class="customs_module">
                <h2 class="pro_title">
                    <el-image :src="preIcon" lazy></el-image>
                    <span style="padding: 0 15px">报关流程说明</span>
                    <el-image
                        :src="preIcon"
                        lazy
                        style="transform: rotate(180deg)"
                    ></el-image>
                </h2>
                <div class="customs_chunks">
                    <div
                        class="customs_view"
                        v-for="(item, index) in customsFlow"
                        :key="index"
                        @click="enterProDetail(item.link)"
                    >
                        <div class="customs_item">
                            <el-image
                                lazy
                                :src="item.url"
                                fit="scale-down"
                                class="customs_icon animate__animated animate__zoomIn"
                            ></el-image>
                            <div class="right_view">
                                <h1
                                    style="
                                        font-weight: 700;
                                        margin: 0;
                                    "
                                >
                                    {{ item.title }}
                                </h1>
                            </div>
                        </div>
                        <div class="customs_detail">了解详情</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="foot_head">
                <div
                    v-for="(item, index) in footList"
                    :key="index"
                    class="head_item"
                >
                    <img :src="item.url" style="width: 50px; height: 50px" />
                    <div class="item_right">
                        <h2>{{ item.title }}</h2>
                    </div>
                </div>
            </div>
            <div class="foot_content">
                <div class="footer-size">
                    <ul style="list-style: none">
                        <p style="font-size: 20px; margin-bottom: 15px">
                            关于我们
                        </p>
                        <li
                            @click="enterProDetail('http://www.nbhuojian.com/')"
                        >
                            公司简介
                        </li>
                        <li
                            @click="enterProDetail('http://www.nbhuojian.com/')"
                        >
                            最新动态
                        </li>
                    </ul>
                    <ul style="list-style: none">
                        <p style="font-size: 20px; margin-bottom: 15px">
                            公司产品
                        </p>
                        <li>F1接单输单系统</li>
                        <li>单证中心</li>
                        <li>放舱网</li>
                    </ul>
                    <ul style="list-style: none">
                        <p style="font-size: 20px; margin-bottom: 15px">
                            产品功能
                        </p>
                        <li>物流跟踪及订阅</li>
                        <li>船期订阅</li>
                        <li>物流实时查询</li>
                        <li>箱货跟踪</li>
                    </ul>
                </div>
                <div
                    style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-right: 10%;
                    "
                >
                    <p class="foot_touch">
                        <el-image
                            :src="phoneIcon"
                            lazy
                            style="width: 20px; height: 20px"
                        ></el-image>
                        <span style="margin-left: 10px">0574-27682901</span>
                    </p>
                    <p class="foot_touch">
                        <el-image
                            :src="mobileIcon"
                            lazy
                            style="width: 20px; height: 20px"
                        ></el-image>
                        <span style="margin-left: 10px">
                            13429380909 13685859945
                        </span>
                    </p>
                    <p class="codeText">欢迎关注订阅放舱网</p>
                    <div class="weixin">
                        <el-image
                            :src="qrCodeFirst"
                            lazy
                            class="qr_img"
                        ></el-image>
                        <el-image
                            :src="qrCodeSecond"
                            lazy
                            class="qr_img"
                        ></el-image>
                    </div>
                </div>
            </div>
            <el-footer class="footer-record">
                <a
                    href="https://www.beian.gov.cn/portal/registerSystemInfo?spm=a21bo.jianhua.1997523009.39.5af92a89VpB9nV&recordcode=33021202002094"
                    class="footer-line"
                    style="margin-right: 8px"
                >
                    <span class="footer-info">浙公网安备 33021202002094号</span>
                </a>
                <a href="https://beian.miit.gov.cn/" class="footer-line">
                    <span class="footer-info">浙ICP备2022012639号-1</span>
                </a>
            </el-footer>
        </div>
        <OpenAds ref="openAdsRef" />
        <!-- <login-dialog
            v-if="loginDialogVisible"
            ref="LoginDialog"
        ></login-dialog> -->
    </div>
</template>
<script>
import "animate.css";
import bus from "@/utils/bus";
import OpenAds from "@/components/open-ads/index.vue";
export default {
    components: {
        OpenAds,
    },
    data() {
        return {
            loginDialogVisible: false,
            HomeBg: require("@/assets/img/mainPageImg/dituback.png"),
            NBImage: require("@/assets/img/mainPageImg/ningbo.png"),
            NBPreView: [
                require("@/assets/img/mainPageImg/high-definitionImgs/ningbo.png"),
            ],
            KCImage: require("@/assets/img/mainPageImg/kuche.png"),
            KCPreView: [
                require("@/assets/img/mainPageImg/high-definitionImgs/kuche.png"),
            ],
            HLJImage: require("@/assets/img/mainPageImg/heilongjiang.png"),
            HLJPreView: [
                require("@/assets/img/mainPageImg/high-definitionImgs/heilongjiang.png"),
            ],
            SCImage: require("@/assets/img/mainPageImg/shache.png"),
            SCPreView: [
                require("@/assets/img/mainPageImg/high-definitionImgs/shache.png"),
            ],
            CHImage: require("@/assets/img/mainPageImg/changchun.png"),
            CHPreView: [
                require("@/assets/img/mainPageImg/high-definitionImgs/changchun.png"),
            ],
            phoneIcon: require("../../assets/img/mainPageImg/phoneIcon.png"),
            mobileIcon: require("../../assets/img/mainPageImg/mobileIcon.png"),
            qrCodeFirst: require("../../assets/qq_.png"),
            qrCodeSecond: require("../../assets/img/mainPageImg/qrCodeSecond.png"),
            detailPreIcon: require("../../assets/img/mainPageImg/detail_preIcon.png"),
            preIcon: require("../../assets/img/mainPageImg/duct_preIcon.png"),
            sufixIcon: require("../../assets/img/mainPageImg/duct_sufixIcon.png"),
            swiperFirstLeftImg: require("@/assets/img/mainPageImg/mainChartHeadLeftImg.png"),
            swiperFirstRightImg: require("@/assets/img/mainPageImg/swiper_first_screen.png"),
            swiperSecondLeftImg: require("@/assets/img/mainPageImg/HeadLeftImgSecond.png"),
            swiperSecondRightImg: require("@/assets/img/mainPageImg/swiper_second_screen.png"),
            swiperSecondRightMap: require("@/assets/img/mainPageImg/swiper_second_map.png"),
            swiperSecondRightIcon: require("@/assets/img/mainPageImg/swiper_second_icon.png"),
            swiperImageList: [
                require("@/assets/img/mainPageImg/high-definitionImgs/FCWThree.png"),
                require("@/assets/img/mainPageImg/high-definitionImgs/FCWfirst.png"),
                require("@/assets/img/mainPageImg/high-definitionImgs/FCWSecond.png"),
            ],
            f1OrderleftImg: require("@/assets/img/mainPageImg/f1Order_leftImg.png"),
            f1OrderFirstImg: require("@/assets/img/mainPageImg/f1Order_firstImg.png"),
            f1OrderSecondImg: require("@/assets/img/mainPageImg/f1Order_secondImg.png"),
            F1Code: require("@/assets/img/mainPageImg/f1Order_secondImg.png"),
            f1OrderImgList: [
                require("@/assets/img/mainPageImg/high-definitionImgs/orderFirst.png"),
                require("@/assets/img/mainPageImg/high-definitionImgs/orderSecond.png"),
            ],
            f1wxleftImg: require("@/assets/img/mainPageImg/f1wx_leftImg.png"),
            f1wxFirstImg: require("@/assets/img/mainPageImg/f1wx_firstImg.png"),
            f1wxSecondImg: require("@/assets/img/mainPageImg/f1wx_secondImg.png"),
            f1wxCode: require("@/assets/img/mainPageImg/f1wx_code.png"),
            f1wxImgList: [
                require("@/assets/img/mainPageImg/f1wx_firstImg.png"),
                require("@/assets/img/mainPageImg/f1wx_secondImg.png"),
            ],
            featureList: [
                {
                    title: "物流跟踪及订阅",
                    url: require("@/assets/img/mainPageImg/guideQueryIcon.png"),
                    detail: "节点实时推送,箱货查询全程无忧,现已支持宁波、上海、青岛、天津、厦门",
                    link: "/logistics",
                    key: "logistics",
                },
                {
                    title: "船期订阅",
                    url: require("@/assets/img/mainPageImg/guideSubIcon.png"),
                    detail: "一键订阅跟踪,实时查询,自动预警,现已支持宁波、上海、青岛、天津、厦门",
                    link: "/shipSubscrib",
                    key: "shipSubscrib",
                },
                {
                    title: "集卡定位",
                    url: require("@/assets/img/mainPageImg/duct_three.png"),
                    detail: "全国集卡车订阅跟踪",
                    link: "/truckSubscribe",
                    key: "truckSubscribe",
                },
                {
                    title: "归大师",
                    url: require("@/assets/img/mainPageImg/guideHsIcon.png"),
                    detail: "HS编码智能查询，7000万+条历史大数据助力推荐，归类不求人",
                    link: "/classAnalysis",
                    key: "classAnalysis",
                },
                {
                    title: "物流实时查询",
                    url: require("@/assets/img/mainPageImg/guideRealTimeIcon.png"),
                    detail: "一键可查,数据可视,数据同步EDI",
                    link: "/boxquery",
                    key: "boxquery",
                },
                // {
                //     title: "资料识别",
                //     url: require("@/assets/img/mainPageImg/guideMainfestIcon.png"),
                //     detail: "托单、提单OK件一键识别",
                //     link: "/fileAnalysis",
                // },
                {
                    title: "供应商目录",
                    url: require("@/assets/img/mainPageImg/guideProviderIcon.png"),
                    detail: "货代/车队/报关行,多元方式助力推广",
                    link: "/advertisement",
                    key: "advertisement",
                },
                {
                    title: "上海舱单发送",
                    url: require("@/assets/img/mainPageImg/guideMainfestIcon.png"),
                    detail: "预配舱单发送平台—操作简单·回执快速·全年可发",
                    link: "/sh_manifest",
                    key: "sh_manifest",
                },
                {
                    title: "指定箱",
                    url: require("@/assets/img/mainPageImg/duct_first.png"),
                    detail: "点击了解详情",
                    key: "designatedBox",
                }
            ],
            customsFlow: [
                {
                    title: "出口报关流程",
                    url: require("@/assets/img/mainPageImg/exitCustomsIcon.png"),
                    link: "https://web.nbhuojian.com/customs-clearance-knowledge-base/#/exit_customs_flow",
                },
                {
                    title: "删改单和出卡",
                    url: require("@/assets/img/mainPageImg/markOutCardIcon.png"),
                    link: "https://web.nbhuojian.com/customs-clearance-knowledge-base/#/mark_outCard_flow",
                },
                {
                    title: "海关查验流程",
                    url: require("@/assets/img/mainPageImg/customsCheckIcon.png"),
                    link: "https://web.nbhuojian.com/customs-clearance-knowledge-base/#/customs_check_flow",
                },
            ],
            productList: [
                {
                    name: "单证中心",
                    img: require("@/assets/img/mainPageImg/duct_first.png"),
                    hideImg: require("@/assets/img/mainPageImg/hideImg1.png"),
                    detail: [
                        "报关F1,一键轻松录入",
                        "全流程无纸化，高效便捷",
                        "支持AEO认证，安全可靠",
                        "解放劳动力，赋能行业",
                    ],
                    hideDetail: [
                        "专业的单证团队，配合上我司自主研发的专业的单证团队，F1接单输单系统，极速响应接单，365天无休服务。",
                        "为广大报关公司提供高质量的报关体验，提升通关时效，降低人工成本提高核心竞争力。",
                    ],
                    link: "http://www.nbhuojian.com/",
                },
                {
                    name: "F1接单输单系统",
                    img: require("@/assets/img/mainPageImg/duct_second.png"),
                    hideImg: require("@/assets/img/mainPageImg/hideImg2.png"),
                    detail: [
                        "专利技术F1一键录入",
                        "OCR+NLP人工智能",
                        "支持海关AEO高级认证",
                        "提效降费，为行业赋能",
                    ],
                    hideDetail: [
                        "国家发明专利一键录入技术，数据录入速度与准确率相比手工录入大幅度提高",
                        "自研系统，一键录入，365天无休服务线上可视化制单，无纸化服务，高效便捷",
                    ],
                    link: "http://www.nbhuojian.com/",
                },
                {
                    name: "放舱网",
                    img: require("@/assets/img/mainPageImg/duct_three.png"),
                    hideImg: require("@/assets/img/mainPageImg/hideImg3.png"),
                    hideDetail: [
                        "专门为国际物流从业者打造的一款海上全链路数据跟踪工具",
                        "通过船名和航次精准定位，实现船舶在各个港口的数据查询功能择优推广",
                    ],
                    detail: [
                        "供应商多方位择优推广",
                        "助力企业物流提效降费",
                        "船期订阅，放舱网小程序实时同步",
                        "全程物流跟踪，物流实时查询",
                    ],
                    link: "http://www.nbhuojian.com/",
                },
            ],
            footList: [
                {
                    url: require("@/assets/img/mainPageImg/footIconFirst.png"),
                    title: "多渠道服务支持",
                },
                {
                    url: require("@/assets/img/mainPageImg/footIconSecond.png"),
                    title: "产品持续迭代",
                },
                {
                    url: require("@/assets/img/mainPageImg/footIconThree.png"),
                    title: "安全数据环境",
                },
            ],
        };
    },
    methods: {
        enterDetail({ link, key }) {
            if (key === "designatedBox") { 
                bus.$emit("open-zdx-detail");
            } else if (!link) {
                this.$message.warning("功能暂未开放");
            } else {
                this.$router.push(link);
            }
        },
        enterProDetail(url) {
            window.open(url, "_blank");
        },
    },
};
</script>
<style lang="stylus">
.el-carousel__indicators > .is-active .el-carousel__button {
    background-color: #d12a2a !important;
}
</style>
<style lang="stylus" scoped>
/deep/.el-carousel__arrow {
    display: none !important;
  }
/deep/.el-image{
    object-fit:contain;
}
.imgHei {
    width: 200px;
    height: 100px;
    position: absolute;
    top: 8%;
    right: -3%;
    object-fit:contain;
  }
  .imgHei:hover {
    transform: scale(1.5);
    transition: transform 0.5s ease-in-out;
  }
  .imgCH {
    width: 200px;
    height: 100px;
    position: absolute;
    top: 34%;
    right: -3%;
  }
  .imgCH:hover {
    transform: scale(1.5);
    transition: transform 0.5s ease-in-out;
  }
  .imgNB {
    width: 200px;
    height: 100px;
    position: absolute;
    top: 76%;
    right: 1%;
  }
  .imgNB:hover {
    transform: scale(1.5);
    transition: transform 0.5s ease-in-out;
  }
  .imgKuche {
    width: 200px;
    height: 100px;
    position: absolute;
    top: 6%;
    right: 30%;
  }
  .imgKuche:hover {
    transform: translateY(40px) scale(1.5);
    transition: transform 0.5s ease-in-out;
  }
  .imgShache {
    width: 200px;
    height: 100px;
    position: absolute;
    top: 17%;
    right: 54%;
    border-radius: 12px;
    overflow: hidden;
  }
  .imgShache:hover {
    transform: scale(1.5);
    transition: transform 0.5s ease-in-out;
  }
.footer {
    background: #141b1e;
    font-size: 16px;
    color: #fff;
    min-height: 400px;
    position: relative;
    .foot_touch{
        margin-top: 5px;
        font-size: 18px;
        width:100%;
        display:flex;
        align-items:center;
    }
    .foot_head {
      margin: auto;
      display: flex;
      justify-content: space-around;
      background-color: #1e282d;
      padding: 1% 10%;
      box-sizing: border-box;
      .head_item {
        width: 23%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        .item_right {
          display: flex;
          flex-direction: column;
          color: white;
          margin-left: 10px;
          h2 {
            font-size: 20px;
            font-weight: 400;
          }
          p {
            margin-top: 8px;
            font-size: 10px;
          }
        }
      }
      .head_item:hover {
        background-color: rgb(36, 35, 35);
      }
    }
    .foot_content {
      display: flex;
      justify-content: space-between;
      padding-top: 30px;
      .footer-size {
        display: flex;
        width: 600px;
        justify-content: space-between;
        margin: 0 0 0 10%;
        ul li {
          margin-top: 12px;
        }
        ul li:hover {
          color: rgb(0, 153, 255);
        }
      }
      .weixin {
        width:100%;
        display:flex;
        justify-content:space-around;
        .qr_img{
            width:80px;
            height:80px;
        }
      }
    }
    .footer-record{
        padding: 20px 0;
        width: 600px;
        display: flex;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        a {
            color: #ffffff;
        }
    }
    .footer-line {
        display: inline-block;
        text-decoration: none;
        height: 20px;
        line-height: 20px;
    }
  }
.product_module_wrap{
    width:100%;
    background-image:url('../../assets/img/mainPageImg/product_outer_Bg.png')
    background-size:100% 100%;
    background-repeat:no-repeat;
}
.product_module{
    padding:20px 0;
    width: 60%;
    min-width: 1100px;
    margin: 0 auto;
    .pro_contain{
        height:380px;
        display:flex;
        justify-content:space-between;
        padding:50px 0;
        .pro_chunk{
            cursor:pointer;
            height:100%;
            min-width:350px;
            background:linear-gradient(#DDE4EB 40%,#F2F3F5,#F2F3F5)
            overflow:hidden;
            position:relative;
            .pro_head{
                min-width:80%;
                display:flex;
                align-items:center;
                justify-content:flex-start;
                .pro_head_title{
                    font-size: 24px;
                    font-weight: 700
                }
            }
            .pro_explain_wrap{
                width:70%;
                margin:0 auto;
            }
            .pro_explain{
                margin-top: 10px;
                font-size:17px;
                list-style:none;
                display:flex;
            }
            .hide_bottom{
                display:flex;
                flex-direction:column;
                justify-content:flex-start;
                align-items:center;
                padding: 20px 0;
                box-sizing:border-box;
            }
            .hide_img{
                width:100%;
                height:100%;
                display:flex;
                justify-content:center;
                position:absolute;
                top:0;
                opacity:0;
                .hide_img_ul{
                    color: white;
                    width: 70%;
                    font-size: 14px;
                    list-style: none;
                    position: absolute;
                    top: 20%;
                }
                li:before {
                    content: "";
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    background-color: white;
                    border-radius: 50%;
                    margin-right:5px;
                }
            }
        }
        .pro_chunk:hover {
            box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
            .hide_img{
                transform: scale(1);
                opacity: 1;
                transition: all .5s ease-out;
            }
        }
    }
}
.carousel {
    position relative;
    .first_bg{
        background-image: url("../../assets/img/mainPageImg/mainChartHeadBg.jpeg");
        background-size: 100% 100%;
    }
    .second_bg{
        background-image: url("../../assets/img/mainPageImg/HeadSecondBg.jpeg");
        background-size: 100% 100%;
    }
    .f1_order_bg{
        height: 100%;
        padding:0 10%;
        border-radius:0 0 15px 15px;
        display:flex;
        justify-content:space-around;
        align-items:center;
        background-image: url("../../assets/img/mainPageImg/f1Order_bg.jpeg");
        background-size: 100% 100%;
    }
    .f1_wx_bg{
        height: 100%;
        padding:0 10%;
        border-radius:0 0 15px 15px;
        display:flex;
        justify-content:space-around;
        align-items:center;
        background-image: url("../../assets/img/mainPageImg/f1wx_bg.jpeg");
        background-size: 100% 100%;
    }
    .home_page_wrap{
        width:100%;
        height:100%;
        display:flex;
        justify-content: center;
        background-color: #fff;
    }
    .home_page{
        position: relative;
    }
    .swiper_first {
        height: 100%;
        padding:0 10%;
        border-radius:0 0 15px 15px;
        display:flex;
        justify-content:space-around;
        align-items:center;
        .swiper_first_lImg{
            object-fit:contain;
        }
        .ship_first_lImg{
            position:absolute;
            top:0;
        }
        .ship_second_map{
            position:absolute;
            top:50%;
            left:40%;
        }
        .ship_second_icon{
            position:absolute;
            top:35%;
            left:36%;
        }
        @media (max-width: 1450px) {
            .ship_first_lImg{
                position:absolute;
                top:10%;
            }
            .ship_second_map{
                position:absolute;
                top:45%;
                left:40%;
            }
            .ship_second_icon{
                width: 130px;
                height: 130px;
                position:absolute;
                top:35%;
                left:36%;
            }
        }
    }
    .order_screen_first{
        position: absolute;
        z-index: 100;
        cursor: pointer;
        top:25%;
        left:20%;
    }
    .order_screen_second{
        position: absolute;
        z-index: 50;
        cursor: pointer;
        top:5%;
        left:10%;
    }
    .f1_screen_first {
        width:250px;
        position: absolute;
        z-index: 100;
        top: 0;
        left: 10%;
        cursor: pointer;
      }
      .f1_screen_second {
        position: absolute;
        z-index: 50;
        bottom: 0%;
        left: 30%;
        cursor: pointer;
      }
      .f1_code {
        position: absolute;
        z-index: 50;
        top: 5%;
        left: 60%;
      }
    /deep/.el-carousel__indicators {
        .el-carousel__button {
            background-color: #d6dae4;
            height: 8px;
            border-radius: 5px;
        }
    }
    /deep/.el-carousel__container {
        height: 500px;
      }
      @media (max-width: 1250px) {
        /deep/.el-carousel__container {
            height: 400px;
          }
      }
}
.pro_title{
    font-size:22px;
    font-weight:700;
    display:flex;
    align-items:center;
    justify-content:center;
}
.feature_module_wrap{
    width:100%;
    background-color:#F3F6F9;
}
.feature_module{
    padding:20px 0;
    min-width:1100px;
    width: 90%;
    gap: 20px;
    margin: 0 auto;
    .duct_chunks{
        height:280px;
        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        padding:50px 0;
        .duct_item{
            width:23%;
            height:120px;
            display:flex;
            justify-content:space-around;
            align-items:center;
            background-image:url('../../assets/img/mainPageImg/productBg.png');
            background-size:100% 100%;
            border-radius:10px;
            cursor:pointer;
            box-shadow: 1px 1px 5px #d7d4d4;
            .duct_icon{
                width: 100px;
                height: 100px;
            }
            .right_view{
                width:55%;
                min-width: 180px;
                display:flex;
                flex-direction:column;
                justify-content:center;
                font-size:13px;
                h1{
                    font-size: 20px;
                }
            }
        }
        .duct_item:hover {
            transform: translateY(-5px);
            transition: transform 0.3s ease-in-out;
        }
    }
}
.customs_module_wrap{
    background-image:url('../../assets/img/mainPageImg/customsContainBg.png');
    background-size:100% 100%;
    background-color: rgba(255, 255, 255, 0.5);
    .customs_module{
        padding:20px 0;
        min-width:1100px;
        width: 60%;
        margin: 0 auto;
        .customs_chunks{
            display:flex;
            flex-wrap:wrap;
            justify-content:space-between;
            padding:50px 0;
            .customs_view{
                width:30%;
                box-shadow: 1px 1px 5px #d7d4d4;
                cursor:pointer;
                overflow:hidden;
                border-radius:10px;
            }
            .customs_view:hover{
                .customs_detail{
                    color:white;
                    background:url('../../assets/img/mainPageImg/customsDetailBg.png')
                    background-repeat:no-repeat;
                    background-size:100% 100%;
                    background-position:center;
                }
            }
            .customs_item{
                padding:10px 20px;
                display:flex;
                justify-content:space-around;
                align-items:center;
                background-image:url('../../assets/img/mainPageImg/productBg.png');
                background-size:100% 100%;
                .customs_icon{
                    width: 80px;
                    height: 80px;
                }
                .right_view{
                    width:55%;
                    display:flex;
                    flex-direction:column;
                    justify-content:center;
                    h1{
                        font-size: 20px;
                    }
                }
            }
            .customs_detail{
                font-weight:bold;
                font-size:18px;
                color:#CE0303;
                width:100%;
                height:60px;
                line-height:60px;
                text-align:center;
                background-color:white;
            }
        }
    }
}
</style>
