import ShManifestTable from "@/views/ShanghaiManifest/components/ShManifestTable.vue";
import AddManifestDialog from "@/views/ShanghaiManifest/components/AddManifestDialog.vue";
import DeleteManifestDialog from "@/views/ShanghaiManifest/components/DeleteManifestDialog.vue";
import ManifestSearchBar from "@/views/ShanghaiManifest/components/ManifestSearchBar.vue";
import {claimCheckBill, claimInputBill, queryShowList} from "@/api/shanghaiManifest";
import {nanoid} from 'nanoid';
import bus from "@/utils/bus.js";
import moment from "moment/moment";

export default {
    components: {
        ShManifestTable,
        AddManifestDialog,
        DeleteManifestDialog,
        ManifestSearchBar
    },
    watch: {},
    data() {
        return {
            fileList: [],
            //table 数据
            currPage: 1,
            totalPage: 0,
            pageSize: 50,
            totalCount: 0,
            dataLoading: false,
            exportDialogVisible: false,
            tableHeaders: this.RESETTABLE.manifestTableTitleList, //列表标题
            tableData: [],
            multipleSelection: [], //多选中的信息
            search_completionstatus: 0, //搜索的舱单状态
            search: {},//搜索框条件，接收SearchBar的返回参数
            loginUserId: '',
            loginUserCompanyId: '',
        }
    },
    mounted() {
        this.init();
    },
    computed: {},
    methods: {
        init() {
            this.resetTableHeaders();
            this.loginUserId = this.$store.state.user.userId;

            // this.search.showUserId = this.loginUserId;
            if (this.$store.state.user.cid) {
                this.loginUserCompanyId = this.$store.state.user.cid;
                this.search.showCompanyId = this.loginUserCompanyId;
            }
            if (this.search_completionstatus === 2) {
                const date_now = moment().format("YYYY-MM-DD");
                const dateRange = [moment(date_now).subtract(0, "days").format("YYYY-MM-DD"), date_now];
                this.search.startDate = dateRange[0] + ' 00:00:00'
                this.search.endDate = dateRange[1] + ' 23:59:59'
            } else if ([0, 1].includes(this.search_completionstatus)) {
                this.search.showUserId = this.$store.state.manifest.showUserId ? this.$store.state.manifest.showUserId : '';
            }
            this.getList();
        }, //初始化界面
        resetTableHeaders() {
            this.tableHeaders = this.RESETTABLE.manifestTableTitleList.filter(x => x.status.includes(this.search_completionstatus));
            this.$forceUpdate()
        },
        searchList(data) {
            this.currPage = 1;
            this.search = data
            this.getList()
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.currPage = 1;
            this.getList();
        },
        nextPage(page) {
            this.currPage = page;
            this.getList();
        },
        getList() {
            let data = {
                ...this.search,
                id: this.$store.state.user.userId,
                completionstatus: this.search_completionstatus === 3 ? '' : this.search_completionstatus,
                page: this.currPage,
                limit: this.pageSize,
            }
            // this.$nextTick(() => {
            //   this.$refs.ShManifestTable.init(this.tableData, this.tableHeaders, 0)
            // })
            this.dataLoading = true;
            queryShowList(data)
                .then((response) => {
                    if (response.data.page) {
                        this.totalPage = response.data.page.totalPage;
                        this.totalCount = response.data.page.totalCount;
                        this.currPage = response.data.page.currPage;
                        this.pageSize = response.data.page.pageSize;
                        this.tableData = response.data.page.list.map((item) => {
                            item.vesselvoyage = item.vesselname + '/' + item.voyageno;
                            item.webtype = item.webtype.split('|')[0];
                            item.total = item.totalpackno + ' / ' + item.totalweight + ' / ' + item.totalvolume;
                            return item
                        });
                    }
                }).finally(() => {
                this.dataLoading = false;
            })

            bus.$emit("getCountNum");
        },

        edit(item) { //编辑舱单
            let id = nanoid(5);
            let path = {
                title: item ? "编辑舱单" : '新建舱单',
                id: id,
                data: item,
                pathName: 'CreateManifest'
            }
            this.$router.push({name: path.pathName, params: path})
        },
        //认领审单
        claimCheck(item) {
            if (item && !item.checkUserId) {

                this.dataLoading = true;
                claimCheckBill({id: item.id}).then((res) => {
                    if (res.data.status) {
                        this.$message.success(res.data.data);
                        this.checkBill(item);
                    }
                }).finally(() => {
                    this.dataLoading = false;

                })
            } else {
                this.checkBill(item);
            }
        },
        claimInput(item) {
            if (item && !item.updateuser) {

                this.dataLoading = true;
                claimInputBill({id: item.id}).then((res) => {
                    if (res.data.status) {
                        this.$message.success(res.data.data);
                        this.edit(item);
                    }
                }).finally(() => {
                    this.dataLoading = false;

                })
            } else {
                this.edit(item);
            }
        },
        checkBill(item) { //编辑舱单
            console.log('checkBill==', item);
            let id = nanoid(5);
            let path = {
                title: '复核舱单',
                id: id,
                isCheck: true,
                data: item,
                pathName: 'CreateManifest'
            }
            this.$router.push({name: path.pathName, params: path})
        },
        copyBill(item) { //复制舱单
            console.log('copyBill==', item);
            let id = nanoid(5);
            let path = {
                title: '复制舱单',
                id: id,
                isCopy: true,
                data: item,
                pathName: 'CreateManifest'
            }
            this.$router.push({name: path.pathName, params: path})
        },
        getSelection(e) { //获取table组件传来的多选信息
            this.multipleSelection = e
        },


    },
}
