<template>
  <div>
    <NoticeTopBar :notice-id="86"></NoticeTopBar>
    <div class="input_div">
      <div v-if="type===0">
        <el-button type="primary" :size="size" @click="openManifestDialog()">新建VGM</el-button>
        <el-button type="primary" plain :size="size" @click="exportDialogVisible = true">VGM表格导入</el-button>
      </div>
      <div style="display:flex;flex-wrap: wrap;">
        <div v-if="type===2" style="margin-right: 10px">
          <el-date-picker
              :size="size"
              v-model="dateRange"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="setDate"
              :picker-options="pickerOptions">
          </el-date-picker>
        </div>
        <el-select class="el_select"
                   :size="size"
                   clearable
                   v-model="search.showUserId"
                   placeholder="选择用户"
        >
          <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
        <el-select class="el_select"
                   :size="size"
                   filterable
                   v-model="search.shipCompany"
                   placeholder="请选择船公司"
                   clearable>
          <el-option
              v-for="item in shipsCompany"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
        <el-input class="inline-input "
                  v-model="search.vessel"
                  :size="size"
                  @keyup.enter.native="go2Search"
                  placeholder="请输入船名"
                  clearable>
        </el-input>
        <el-input class="inline-input "
                  v-model.trim="search.ctnNo"
                  :size="size"
                  @keyup.enter.native="go2Search"
                  placeholder="请输入箱号"
                  clearable
                  maxlength="20">
        </el-input>
        <el-input class="inline-input "
                  v-model.trim="search.billNo"
                  :size="size"
                  @keyup.enter.native="go2Search"
                  placeholder="请输入提单号"
                  clearable
                  maxlength="30">
        </el-input>
        <el-button type="primary" :size="size" @click="go2Search">查询</el-button>
      </div>

      <div>
        <el-button class="re_send" size="medium" @click="reSendVGM" v-if="type===1">重新发送</el-button>
        <el-button type="warning" size="medium" @click="sendVGM" v-if="type===1">发送</el-button>
        <el-button type="primary" size="medium" plain @click="exportVgmHistoryExcel('未发送')"
                   v-if="[0,1].includes(type)">导出
        </el-button>
        <el-button type="primary" size="medium" plain @click="exportVgmBillExcel()" v-if="type===2">
          导出账单
        </el-button>
        <el-button type="danger" size="medium" plain style="margin-right:1rem;" @click="toDelete()">删除</el-button>
      </div>
    </div>
    <el-dialog title="VGM表格导入" :visible.sync="exportDialogVisible" width="30%" center>
      <div>
        <span>1、模板类型：</span>
        <el-select v-model="templateLable" placeholder="模版选择" @change="onTemplateChange">
          <el-option
              v-for="item in templateList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="export_module">
        <p>2、可先下载模板按照格式填写后上传</p>
      </div>
      <el-upload style="margin:2rem auto;text-align:center;"
                 :file-list="fileList" :auto-upload="false" class="upload-demo" drag action=""
                 :on-change="fileUpload" multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toDownload()">下载模板</el-button>
      </span>
    </el-dialog>
    <DeleteManifestDialog ref="DeleteManifestDialog" v-if="deleteDialogVisible" @refresh="go2Search">
    </DeleteManifestDialog>
    <ReSendManifestDialog ref="ReSendManifestDialog" v-if="reSendDialogVisible" @refresh="go2Search">
    </ReSendManifestDialog>
  </div>
</template>

<script>
import DeleteManifestDialog from "./DeleteVGMDialog.vue";
import ReSendManifestDialog from "./ReSendVGMDialog.vue";
import NoticeTopBar from "@/views/ShanghaiManifest/components/NoticeTopBar.vue";
import {exportShipExcel, getAllShipCompanyData, sendShMould} from "@/api/shanghaiManifest";
import moment from "moment";
import {getAllCompanyStaff} from "@/api";
import {onSend, exportVgmHistoryExcelReq,exportVgmBillExcelReq,importExcelModule, downloadExcelModule} from "@/api/vgm";
import {getAllCompanyStaffById} from "@/api/company";

export default {
  name: "VGMSearchBar",
  components: {NoticeTopBar, DeleteManifestDialog, ReSendManifestDialog},
  props: {
    multipleSelection: {//选中的信息
      type: Array,
      default: () => []
    },
    type: {
      type: Number,
      default: 0//0:草稿箱，1：待发送，2：已发送
    }
  },
  data() {
    return {
      shipsCompany: [],
      userList: [],//企业用户可以筛选查看其他同事的单子
      loginId: '',
      size: 'medium',
      loginUserId: '',
      loginUserCompanyId: '',
      reSendDialogVisible: false,
      deleteDialogVisible: false,
      fileList: [],
      exportDialogVisible: false,//列表模板下载
      templateList: [{
        value: '1',
        label: '普通模板'
      }, {
        value: '2',
        label: '长荣模板'
      }],
      templateLable: '普通模板',
      selectedTemplateValue: '1',
      search: {
        showUserId: '',
        showCompanyId: this.loginUserCompanyId, //筛选相应企业
        shipCompany: '',
        billNo: '', //搜索的提单号
        ctnNo: '', //箱号
        vessel: '', //搜索的船名
        beginTime: '',
        endTime: ''
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateRange: ''//日期时间范围
    }
  },
  mounted() {
    this.init();
    this.searchShipCompany();
  },
  methods: {
    init() {
      this.loginId = this.$store.state.user.loginId;
      this.loginUserId = this.$store.state.user.userId;
      this.resetUserList();
      if (this.$store.state.user.cid) {
        this.loginUserCompanyId = this.$store.state.user.cid;
        this.search.showCompanyId = this.loginUserCompanyId;
        this.getCompanyUsers(this.loginUserCompanyId)
      }
      if (this.type === 2) {
        this.setDateToToday();
      }
    },
    setDateToToday() {
      const date_now = moment().format("YYYY-MM-DD");
      this.dateRange = [moment(date_now).subtract(0, "days").format("YYYY-MM-DD"), date_now];
      this.setDate(this.dateRange);
    },
    searchShipCompany(keyword = '') {
      const data = {keyWord: keyword.trim()}
      getAllShipCompanyData(data).then(({data}) => {
        this.shipsCompany = data.data.map(x => {
          return {
            id: x.id,
            label: x.codeName,
            value: x.code + '—' + x.simpleName,
            vgmRemark: x.vgmRemark,
            isVgmSoNo: x.isVgmSoNo,
            isVgmWeightDetail: x.isVgmWeightDetail,
            isVgmDate: x.isVgmDate,
            isVgmAddress: x.isVgmAddress,
          }
        });
      });
    },
    onTemplateChange(val) {
      this.selectedTemplateValue = val;
    },
    resetUserList() {
      this.userList = [];
      const user = {
        id: this.loginUserId,
        label: '只看自己',
        value: this.loginUserId,
      };
      this.userList.push(user);
      this.search.showUserId = '';

    },
    getCompanyUsers(id) {
      this.userList = [];
      getAllCompanyStaffById({
        id: id,
      }).then((res) => {
        if (res.data.data.users) {
          this.userList = res.data.data.users.map(item => {
            return {
              id: item.id,
              label: this.loginUserId === item.id ? '只看自己' : item.userName,
              value: item.id,
            }
          })
        }
        const item = {
          id: '',
          label: '全部',
          value: null,
        }
        this.userList.unshift(item);

      }).catch((err) => {
        console.error(err);
      });
    },
    go2Search() {
      this.$emit('search', this.search)
    },
    //下载舱单模板
    toDownload() {
      let templateValue = this.selectedTemplateValue
      downloadExcelModule(templateValue).then((res) => {
        if (res.status === 200) {
          if (templateValue === '1') {
            this.saveFile(res.data, `Vgm数据导入模板.xlsx`)
          }
          if (templateValue === '2') {
            this.saveFile(res.data, `长荣VGM导入模板.xls`)
          }
        }
      })
    },
    //导出列表
    exportExcel() {
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: "warning",
          message: "只能选择一条舱单记录！"
        })
      } else if (this.multipleSelection.length === 1) {
        exportShipExcel(this.multipleSelection[0].id)
            .then((response) => {
              if (response.status === 200) {
                this.saveFile(response.data, `${this.multipleSelection[0].billno}${moment().format('YYYYMMDDHHmmss')}舱单.xlsx`)
              }
            })
      } else {
        this.$message({
          type: "warning",
          message: "请选择一条舱单记录！"
        })
      }
    },
    //导出Vgm发送记录列表
    exportVgmHistoryExcel(text) {
      if (this.multipleSelection.length > 0) {
        const ids = this.multipleSelection.map(item => item.id);
        this.exportLoading = true;
        exportVgmHistoryExcelReq(ids)
            .then((response) => {
              if (response.status === 200) {
                this.saveFile(response.data, text + `VGM列表.xlsx`)
              }
            }).finally(() => {
          this.exportLoading = false;
        })
      } else {
        this.$message({
          type: "warning",
          message: "请选择一条VGM记录！"
        })
      }
    },
    extractDateOnly(dateString) {
      if (!dateString) return '';
      const match = dateString.match(/^(\d{4}-\d{2}-\d{2})/);
      return match ? match[1] : '';
    },
    //导出Vgm发送记录列表
    exportVgmBillExcel() {
      if (this.search.beginTime && this.search.endTime) {
        const beginTime = this.extractDateOnly(this.search.beginTime);
        const endTime = this.extractDateOnly(this.search.endTime);
        console.log("beginTime" + beginTime)
        console.log("endTime"+endTime)
        exportVgmBillExcelReq(this.search)
            .then((response) => {
              if (response.status === 200) {
                this.saveFile(response.data, beginTime+`至`+endTime+`VGM账单.xlsx`)
              }
            }).finally(() => {
          this.exportLoading = false;
        })
      } else {
        this.$message({
          type: "warning",
          message: "请选择开始日期和结束日期"
        })
      }
    },
    //保存文件
    saveFile(data, fileName) {
      let blob = new Blob([data], {
        type: "application/vnd.ms-excel;charset=utf-8"
      }); // res就是接口返回的文件流了
      let url = window.URL || window.webkitURL;
      let fileURL = url.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = fileURL;
      a.download = fileName;
      a.target = "_self";
      a.click();
      url.revokeObjectURL(fileURL);
    },
    openManifestDialog() { //打开舱单编辑或新建仓单
      this.$emit('edit')
    },
    //上传模板导入舱单
    fileUpload(file) {
      let templateValue = this.selectedTemplateValue
      let filename = file.name
      let fileSuffix = filename.substr(filename.lastIndexOf('.') + 1);
      if (fileSuffix !== 'xlsx' && fileSuffix !== 'xls') {
        this.$message({
          type: "warning",
          message: "只允许上传xlsx文件！"
        })
        return false
      }
      importExcelModule({file: file.raw, id: this.$store.state.user.userId, templateValue})
          .then((response) => {
            if (response.data.status) {
              this.$message({
                type: "success",
                message: "导入成功，数据已保存在草稿箱！"
              })
              this.exportDialogVisible = false;
              this.fileList = []
              this.go2Search();
            } else {
              this.$message.error(response.data.message);
            }
          })
    },
    setDate(val) {
      console.log(val)
      if (val) {
        this.search.beginTime = val[0] + ' 00:00:00'
        this.search.endTime = val[1] + ' 23:59:00'
      } else {
        this.search.beginTime = ''
        this.search.endTime = ''
      }
      this.go2Search();
    },
    //发送
    sendVGM() {
      if (this.multipleSelection.length > 0) {
        const data = [];
        this.multipleSelection.forEach((item) => {
          data.push(item.id);
        })
        onSend(data).then(response => {
          if (response.data.status) {
            this.$message({
              type: "success",
              message: "发送成功"
            })
          }
        }).finally(() => {
          this.go2Search();
        })
      } else {
        this.$message({
          type: "error",
          message: "请选择要发送的舱单！"
        })
      }
    },
    reSendVGM() {
      if (this.multipleSelection.length > 0) {
        const reSendData = [];
        this.multipleSelection.forEach((item) => {
          reSendData.push(item);
        })
        this.reSendDialogVisible = true;
        this.$nextTick(() => {
          this.$refs.ReSendManifestDialog.init(reSendData);
        })
      } else {
        this.$message({
          type: "error",
          message: "请选择要重新发送的vgm！"
        })
      }
    },
    //删除舱单
    toDelete() {
      if (this.multipleSelection.length > 0) {
        const deleteData = [];
        this.multipleSelection.forEach((item) => {
          deleteData.push(item);
        })
        this.deleteDialogVisible = true;
        this.$nextTick(() => {
          this.$refs.DeleteManifestDialog.init(deleteData);
        })
      } else {
        this.$message({
          type: "error",
          message: "请选择要删除的vgm！"
        })
      }
    },
  },
}
</script>


<style scoped lang="stylus">
.input_div {
  margin: 1rem 0;
  display: flex;
  border-radius: 4px;
  justify-content: space-between;
  background: var(--GRAY-f1f3f6);

  .inline-input {
    margin-right: 1rem;
    width: 20rem;
  }
}

.el_select {
  margin-right: 1rem;
  max-width: 16rem;
  min-width: 14rem;
  display: block;
}

.el-date-editor--daterange.el-input__inner {
  width: 240px;
}

.re_send {
  background-color: rgb(255, 245, 235);
  color: rgb(255, 150, 64);
  border-color: rgb(255, 150, 64);
}
</style>
