<template>
    <div class="top_div" :class="`${showTab}_banner`">
        <slot></slot>
        <div class="top_banner_action animate__animated">
            <el-tooltip content="点击了解详情" placement="top">
                <!-- 添加new角标 -->
                <div class="zdx-btn" @click="openZdxDetail">
                    <span class="new-tag">NEW</span>
                    指定箱业务
                </div>
            </el-tooltip>
        </div>
    </div>
</template>
<script>
import "animate.css";
import bus from "@/utils/bus.js";

export default {
    name: "TopBanner",
    props: {
        showTab: {
            type: String,
            default: "NB",
        },
    },
    mounted() {
        const bannerActionEle = document.querySelector(".top_banner_action");
        bus.$on("open-zdx-btn",()=>{
            bannerActionEle.classList.add("animate__tada");
        })
        // 监听动画结束
        bannerActionEle.addEventListener("animationend", () => {
            bannerActionEle.classList.remove("animate__tada");
        })
        bus.$on("tab-change", (data) => {
            if(data.type === "blno"){
                bannerActionEle.classList.add("animate__tada");
            }
        })
    },
    methods: {
        openZdxDetail(){
            bus.$emit("open-zdx-detail")
        }
    }
};
</script>
<style lang="stylus">\
.top_div{
    position: relative;
    .top_banner_action{
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1000;
        overflow hidden
        .zdx-btn{
            background-color: #fff;
            color: #b87100;
            padding: 6px 20px 6px 16px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 14px;
            font-weight: bold;
            overflow: hidden;
            &:hover{
                background-color: #f0f0f0;
            }
        }
        .new-tag{
            position: absolute;
            top: 0;
            right: 0;
            transform: rotate(45deg) translate(28%,-70%);
            background-color: #b87100;
            color: #fff;
            font-size: 10px;
            padding: 0 16px;
        }
    }
}
.NB_banner {
    background-image: linear-gradient(
        var(--NB-RED-FIRST),
        var(--NB-RED-SECOND),
        var(--NB-RED-FIRST)
    )
}
.SH_banner {
    background-image: linear-gradient(
        var(--SH-BLUE-FIRST),
        var(--SH-BLUE-SECOND),
        var(--SH-BLUE-FIRST)
    );
}
.QD_banner {
    background-image: linear-gradient(
        var(--QD-GREEN-FIRST),
        var(--QD-GREEN-SECOND),
        var(--QD-GREEN-FIRST)
    );
}
.TJ_banner {
    background-image: linear-gradient(
        var(--TJ-YELLOW-FIRST),
        var(--TJ-YELLOW-SECOND),
        var(--TJ-YELLOW-FIRST)
    );
}
.XM_banner {
    background-image: linear-gradient(
        var(--XM-PURPLE-FIRST),
        var(--XM-PURPLE-SECOND),
        var(--XM-PURPLE-FIRST)
    );
}
.SZ_banner {
    background-image: linear-gradient(
        var(--SZ-ORANGE-FIRST),
        var(--SZ-ORANGE-SECOND),
        var(--SZ-ORANGE-FIRST)
    );
    .header_footer{
        color: #ffffff !important;
    }
}
</style>
