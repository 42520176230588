<template>
  <div>
    <el-dialog title="舱单详情" :visible.sync="centerDialogVisible" width="90%" center>
      <div class="the_content">
        <BaseFormModule :item="baseForm" ref="baseForm" :is-look="!needUpdate"></BaseFormModule>
        <ShipFormModule :item="baseForm" ref="shipForm" :is-look="!needUpdate"></ShipFormModule>
        <SFTFormModule ref="consignerForm" :item="consignerForm" :type-val="{title:'发货人',code:0}"
                       :is-look="!needUpdate"
        ></SFTFormModule>
        <SFTFormModule ref="consigneeForm" :item="consigneeForm" :type-val="{title:'收货人',code:1}"
                       :is-look="!needUpdate"
        ></SFTFormModule>
        <SFTFormModule ref="informersForm" :item="informersForm" :type-val="{title:'通知人',code:2}"
                       :is-look="!needUpdate"
        ></SFTFormModule>
        <div class="the_title">货物明细</div>
        <MainfestGoodsTable ref="ManifestGoodsTable" :item="goodsList" :is-look="!needUpdate"></MainfestGoodsTable>
        <div v-if="vgmDetail">
          <div class="the_title2">舱单已发送，如要修改请到VGM页面</div>
          <AddVGMDialog
              :is-manifest-sub="true"
              :is-look="!needUpdate"
              ref="ManifestVGM"
          ></AddVGMDialog>
        </div>
      </div>
<!--      <span slot="footer" class="dialog-footer" v-if="showCheck">-->
<!--        <el-button type="primary" @click="operator('confirm')" :disabled="countTime>0">-->
<!--          确认无误<span v-if="countTime>0">（{{ countTime }}s）</span>-->
<!--                </el-button>-->
<!--        <el-button type="warning" @click="operator('update')">-->
<!--          修 改-->
<!--        </el-button>-->
<!--        <el-button @click="operator('cancel')">-->
<!--          取 消-->
<!--        </el-button>-->
<!--      </span>-->
    </el-dialog>
  </div>
</template>

<script>

import MainfestGoodsTable from "@/views/ShanghaiManifest/components/goods/ManifestGoodsTable.vue";
import BaseFormModule from "@/views/ShanghaiManifest/components/baseInfo/BaseFormModule.vue";
import SFTFormModule from "@/views/ShanghaiManifest/components/sft/SFTFormModule.vue";
import ShipFormModule from "@/views/ShanghaiManifest/components/shipInfo/ShipFormModule.vue";
import {
  getAllCountryCode,
  saveHead,
  getDetailById,
} from "@/api/shanghaiManifest";
import AddVGMDialog from "@/views/VGM/components/AddVGMDialog.vue";
import bus from "@/utils/bus";

export default {
  components: {AddVGMDialog, ShipFormModule, SFTFormModule, BaseFormModule, MainfestGoodsTable},
  data() {
    return {
      centerDialogVisible: false,
      showCheck: false,//是否显示审单确认按钮
      needUpdate: false,//如果审单发现问题需要修改，设为true
      detail: {
        head: {},
        contacts: [],
        goods: []
      },
      baseForm: {},
      //发货人信息
      consignerForm: {},
      //收货人
      consigneeForm: {},
      //通知人
      informersForm: {},
      //货物明细
      goodsList: [],
      vgmDetail: null,
      countTime: 5,//如果显示确认无误按钮，倒计时5秒才可点击
      timer: '',//倒计时
    }
  },
  methods: {
    init(item, showCheck = false) {
      this.centerDialogVisible = true;
      this.showCheck = showCheck;
      // console.log('item detail', item);
      this.getDetail(item.id)
      if (this.showCheck) {
        this.timer = setInterval(() => {
          this.countTime--;
          if (this.countTime === 0) {
            clearInterval(this.timer);
          }
        }, 1000);
      }
    },
    getDetail(id) {
      getDetailById({id: id})
          .then((response) => {
            this.detail = response.data.data;
            this.baseForm = this.detail.head;
            this.consignerForm = this.detail.contacts.filter(x => x.type === 0)[0];
            this.consigneeForm = this.detail.contacts.filter(x => x.type === 1)[0];
            this.informersForm = this.detail.contacts.filter(x => x.type === 2)[0];
            this.goodsList = this.detail.goods
            if (this.detail.vgm) {
              this.vgmDetail = this.detail.vgm;
            } else {
              this.vgmDetail = null;
            }
            this.$nextTick(() => {
              bus.$emit('refreshVGM', this.vgmDetail);
            })
          })
    },
    operator(type) {//edit,delete,copy
      if (type === 'update') {
        this.needUpdate = true;
      } else if (type === 'confirm') {

      } else {
        this.$emit(type);
        clearInterval(this.timer);
        this.centerDialogVisible = false;
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.the_content {
  height: 70vh;
  clear: both;
  overflow-y: scroll;

  .the_title {
    user-select: none;
    cursor: pointer;
    padding: 0.6rem 0;
    font-size: 16px;
    text-align: center;
    background: var(--GRAY-dcdcdc);
    margin-bottom: 1rem;
  }
}

.the_title2 {
  user-select: none;
  cursor: pointer;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin: 1rem 0;
}
</style>
