<template>
    <div class="tabbar-group-wrap">
        <div class="slider-item"></div>
        <div class="tabbar-group" v-if="loopTabs && loopTabs.length > 0">
            <div
                v-for="(item, index) in loopTabs"
                :key="item.value"
                class="flex-wrap"
            >
                <div
                    :class="{
                        'tabbar-item': true,
                        active: value === item.value,
                        [item.value]: true,
                    }"
                    @click="handleTabClick(item.value)"
                >
                    {{ item.label }}
                </div>
                <div
                    class="divider-line"
                    v-if="index !== loopTabs.length - 1"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import bus from "@/utils/bus.js";

const tabs = [
    {
        label: "宁波",
        value: "NB",
    },
    {
        label: "上海",
        value: "SH",
    },
    {
        label: "青岛",
        value: "QD",
    },
    {
        label: "天津",
        value: "TJ",
    },
    {
        label: "厦门",
        value: "XM",
    },
    {
        label: "深圳",
        value: "SZ",
    },
];

export default {
    name: "TabBarGroup",
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: {
            type: String,
            default: "NB",
        },
        type: {
            type: String,
            // blno 提单 ship 船期,SZ没有船期
            default: "blno",
        },
    },
    emits: ["change"],
    data() {
        return {
            loopTabs: [],
        };
    },
    // computed: {
    //     loopTabs() {
    //         if (!this.value || !this.type) {
    //             return tabs;
    //         }
    //         const szTabs= tabs.filter((item) => item.value !== "SZ");
    //         return this.value === "SZ" && this.type === "ship"
    //             ? szTabs
    //             : tabs;
    //     },
    // },
    watch: {
        value(val) {
            if (this.type === "ship" && val === "SZ") {
                this.changeSliderPos("NB");
                return;
            }
            this.changeSliderPos(val);
        },
    },
    methods: {
        handleTabClick(val) {
            this.$emit("change", val);
            bus.$emit("tab-change", {
                value: val,
                type: this.type,
            })
        },
        changeSliderPos(val) {
            // 避免实际没更新但是动画执行了的情况
            this.$nextTick(() => {
                if (this.value !== val) {
                    return;
                }
                const curActiveTab = document.getElementsByClassName(val)[0];
                const sliderItem =
                    document.getElementsByClassName("slider-item")[0];
                if (sliderItem && curActiveTab) {
                    anime({
                        targets: `.slider-item`,
                        left: curActiveTab.offsetLeft,
                        top: curActiveTab.offsetTop,
                        height: curActiveTab.clientHeight,
                        width: curActiveTab.clientWidth,
                        duration: 800,
                    });
                    if (sliderItem.classList[1]) {
                        sliderItem.classList.remove(sliderItem.classList[1]);
                    }
                    sliderItem.classList.add(`active-${val}`);
                }
            });
        },
    },
    mounted() {
        if (!this.value || !this.type) {
            this.loopTabs = tabs;
        }
        const szTabs = tabs.filter((item) => item.value !== "SZ");
        this.loopTabs =
            this.type === "ship" ? szTabs : tabs;
        this.$nextTick(() => {
            this.changeSliderPos(this.value);
        });
    },
};
</script>

<style lang="stylus">
.tabbar-group-wrap{
    position: relative;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    overflow: hidden;
    .flex-wrap{
        display:flex;
        align-items:center;
    }
    .divider-line{
        width: 1px;
        height: 24px;
        margin: 0 2px;
        background: #cfcfcf;
    }
    .slider-item{
        position: absolute;
        height: 36px;
        background: green;
        z-index: 1;
        border-radius: 4px;
        overflow: hidden;
        &.active-NB{
            background-image: linear-gradient(
                var(--NB-RED-FIRST),
                var(--NB-RED-SECOND),
                var(--NB-RED-FIRST)
            ) !important;
        }
        &.active-SH{
            background-image: linear-gradient(
                var(--SH-BLUE-FIRST),
                var(--SH-BLUE-SECOND),
                var(--SH-BLUE-FIRST)
            ) !important;
        }
        &.active-QD{
            background-image: linear-gradient(
                var(--QD-GREEN-FIRST),
                var(--QD-GREEN-SECOND),
                var(--QD-GREEN-FIRST)
            ) !important;
        }
        &.active-TJ{
            background-image: linear-gradient(
                var(--TJ-YELLOW-FIRST),
                var(--TJ-YELLOW-SECOND),
                var(--TJ-YELLOW-FIRST)
            ) !important;
        }
        &.active-XM{
            background-image: linear-gradient(
                var(--XM-PURPLE-FIRST),
                var(--XM-PURPLE-SECOND),
                var(--XM-PURPLE-FIRST)
            ) !important;
        }
        &.active-SZ{
            background-image: linear-gradient(
                var(--SZ-ORANGE-FIRST),
                var(--SZ-ORANGE-SECOND),
                var(--SZ-ORANGE-FIRST)
            );
        }
    }
    .tabbar-group{
        display: flex;
        align-items: center;
        z-index: 2;
        position: relative;
        height: 100%;
        padding: 4px;
        box-sizing: border-box;
        .tabbar-item{
            font-size: 16px;
            font-weight: bold;
            height: 100%;
            padding: 4px 12px;
            box-sizing: border-box;
            cursor: pointer;
            text-wrap:nowrap;
            &.active{
                color: #ffffff;
                background: transparent;
            }
        }
    }
}
</style>
