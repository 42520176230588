<template>
  <div>
    <div class="the_title" @click="!isManifestSub? basicalInformation = !basicalInformation:''" v-if="!isManifestSub">
      基本信息
      <i :class="basicalInformation?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
    </div>
    <div v-show="basicalInformation">
      <el-form :inline="true" :model="form" ref="baseForm" class="demo-form-inline" label-position="right"
               label-width="100px" :rules="baseRules">

        <el-form-item label="提单号：" prop="billNo" v-if="!isManifestSub">
          <el-input
              :disabled="isLook"
              :size="size"
              v-enterNextInput
              v-model="form.billNo"
              @input="inputFilter($event,'billNo')"
              clearable
              placeholder="只能是大写字母或数字">
          </el-input>
        </el-form-item>
        <el-form-item label="船公司：" prop="shipCompany" v-if="!isManifestSub">
          <el-select
              :disabled="isLook"
              :size="size"
              v-enterNextInput
              v-model="form.shipCompany"
              placeholder="请选择船公司"
              @change="selectShip"
              filterable
              clearable>
            <el-option
                v-for="item in shipsCompany"
                :key="item.value"
                :label="item.label"
                :disabled="item.disabled"
                :value="item.value">
            </el-option>
          </el-select>
          <div style="width:600px;height: 20px;font-size: 10px;line-height: 20px;color: blue;position: absolute;">
            {{ handleShipRemark(this.form.shipCompany) }}
          </div>
        </el-form-item>
        <el-form-item v-if="!(isManifestSub||hideVesselAndVoyage)" label="船名：" prop="vessel">
          <el-input
              :disabled="isLook"
              :size="size"
              class="the_base"
              v-enterNextInput
              @input="inputFilter2($event,'vessel')"
              v-model="form.vessel"
              @blur="form.vessel =$event.target.value.trim()"
              placeholder="请输入船名,只能是大写字符和数字"
              clearable></el-input>
        </el-form-item>
        <el-form-item v-if="!(isManifestSub||hideVesselAndVoyage)" label="航次：" prop="voyage">
          <el-input
              :disabled="isLook"
              :size="size"
              class="the_base"
              @input="inputFilter2($event,'voyage')"
              v-enterNextInput
              v-model.trim="form.voyage"
              @blur="form.voyage =$event.target.value.trim()"
              placeholder="请输入航次"
              clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="form.isSendSync" :true-label="1" :false-label="0" v-if="isManifestSub"
                       :disabled="isLook">
            勾选后，VGM将和舱单同时发送
          </el-checkbox>
        </el-form-item>
        <el-form-item v-if="!hideVesselAndVoyage" label="码头：" prop="wharfCode">
          <el-select
              :disabled="isLook"
              :size="size"
              class="the_base"
              v-enterNextInput
              value-key="id"
              v-model="form.wharfCode"
              @change="setWharf"
              filterable
              placeholder="请选择"
              clearable>
            <el-option
                v-for="item in wharfList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
          <el-button v-if="!isLook" :size="size" type="primary"
                     @click="getWharfInfo(0)"
                     :loading="getWharfInfoLoading">获取码头
          </el-button>
        </el-form-item>
        <el-form-item label="SO号：" prop="soNo" v-if="handleShipSONeed(this.form.shipCompany)">
          <el-input
              :disabled="isLook"
              :size="size"
              class="the_base"
              @input="inputFilter2($event,'soNo')"
              v-enterNextInput
              v-model.trim="form.soNo"
              @blur="form.soNo =$event.target.value.trim()"
              placeholder="请输入SO号"
              clearable></el-input>
        </el-form-item>
        <el-form-item label="截VGM日期" prop="vgmDate" v-if="handleShipDateNeed(this.form.shipCompany)">
          <el-date-picker
              :disabled="isLook"
              v-model="form.vgmDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择VGM截止日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="持箱人：" prop="ctnSocMark" v-if="!isManifestSub">
          <el-radio-group v-model="form.ctnSocMark" :disabled="isLook">
            <el-radio label="N">COC船公司自有箱</el-radio>
            <el-radio label="Y">SOC客户自备箱</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-button type="text" @click="autoVGM" v-if="isManifestSub&&!isLook">重新生成VGM</el-button>
        <el-button type="text" class="delete_span" @click="removeVGM" v-if="isManifestSub&&!isLook">取消VGM</el-button>

      </el-form>
    </div>
    <GetWharfDialog ref="GetWharfDialog" @changeWharf="changeWharf" @sure="wharfSure"></GetWharfDialog>
  </div>
</template>
<script>
import GetWharfDialog from "@/views/VGM/components/GetWharfDialog.vue";


import {setRightForrmat, setRightForrmatPlus} from "@/utils/validate";
import {getShWharfList, reSend} from "@/api/vgm";
import {getAllShipCompanyData, checkVesselsVoyageInfo} from "@/api/shanghaiManifest";

export default {
  name: "BaseFormModule",
  props: {
    isManifestSub: {//true代表作为舱单编辑页面组件，需要隐藏重复的输入项
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "medium"
    },
    isLook: {//查看，不能编辑
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {
      }
    },
    shipsCompany: {
      type: Array,
      default: () => []
    },
  },
  components: {
    GetWharfDialog
  },
  watch: {
    item(newVal) {
      if (newVal) {
        this.form = newVal;
        this.$forceUpdate();
      }
    },
    'form.shipCompany': function (newVal) {
      if (newVal && newVal.includes('长荣')) {
        this.hideVesselAndVoyage = true;
      } else {
        this.hideVesselAndVoyage = false;
      }
      this.selectShip(newVal);
    }
  },
  computed: {
    handleShipRemark() {
      return function (ship) {
        let fiItem = this.shipsCompany.filter(item => item.value === ship)
        return fiItem[0]?.vgmRemark || ''
      }
    },
    //判断船司是否需要SO号
    handleShipSONeed() {
      return function (ship) {
        let fiItem = this.shipsCompany.filter(item => item.value === ship)
        const result = fiItem[0]?.isVgmSoNo === 1 || false;
        if (!result && !this.isLook) {
          this.form.soNo = '';
        }
        return result
      }
    },
    //判断船司是否需要VGM日期
    handleShipDateNeed() {
      return function (ship) {
        let fiItem = this.shipsCompany.filter(item => item.value === ship)
        const result = fiItem[0]?.isVgmDate === 1
        if (!result && !this.isLook) {
          this.form.vgmDate = '';
        }
        return result
      }
    },
    //判断船司是否需要称重方地址,箱皮重和货重
    handleShipAddressNeed() {
      return function (ship) {
        let fiItem = this.shipsCompany.filter(item => item.value === ship)
        //包含在列表里代表不显示
        let props = [];
        if (fiItem[0]?.isVgmAddress === 0) {
          props.push('vgmAddress');
        }
        if (fiItem[0]?.isVgmWeightDetail === 0) {
          props.push('vgmNetWeight');
          props.push('vgmTareWeight');
        }
        return props
      }
    },
  }
  ,
  data() {
    const validateBillNo = (rule, value, callback) => {
      if (!value || value.length < 6 || value.length > 30) {
        callback(new Error('提单号不符合,长度在6-30字符'));
      } else if (!(/(^[A-Z0-9]+)$/.test(value))) {
        callback(new Error('提单号只能是大写字母和数字'));
      } else {
        callback();
      }
    };
    const validateVessel = (rule, value, callback) => {
      if (!(/(^[A-Z0-9.\- ]+)$/.test(value))) {
        callback(new Error('船名只能是大写字母 数字 . -'));
      } else {
        callback();
      }
    };
    const validateVoyageNo = (rule, value, callback) => {
      if (!(/(^[A-Z0-9. ]+)$/.test(value))) {
        callback(new Error('航次只能只能是大写字母 数字 .'));
      } else if (value.includes('v.') || value.includes('V.')) {
        callback(new Error('航次不能有 V.'));
      } else {
        callback();
      }
    };
    return {
      wharfList: [],//码头列表
      basicalInformation: true, //基本信息
      getWharfInfoLoading: false,
      baseRules: {
        billNo: [
          {
            required: true,
            message: "请输入主提单号",
            trigger: "blur",
          },
          {
            min: 6,
            max: 30,
            message: "提单号不符合,长度在6-30字符",
            trigger: "blur",
          },
          {validator: validateBillNo, trigger: 'blur'},
        ],
        shipCompany: [
          {
            required: true,
            message: "请选择船公司",
            trigger: "change",
          },
        ],
        wharfCode: [
          {
            required: true,
            message: "请选择码头",
            trigger: "blur",
          },
        ],
        vessel: [
          {
            required: true,
            message: "请输入船名",
            trigger: "change",
          },
          {validator: validateVessel, trigger: 'blur'},
        ],
        voyage: [
          {
            required: true,
            message: "请输入航次",
            trigger: "change",
          },
          {validator: validateVoyageNo, trigger: 'blur'},
        ],
        soNo: [
          {
            required: true,
            message: "请输入SO号", trigger: "blur"
          },
        ],
        vgmDate: {required: true, message: "请选择VGM截止时间", trigger: "blur"},
        ctnSocMark: [
          {
            required: true,
            message: "请选择货主箱标志",
            trigger: "change",
          },
        ]
      },
      form: {
        id: '',
        //基本信息
        billNo: "", //提单号
        soNo: "", //SO号
        vgmDate: "", //截VGM时间
        shipCompany: "", //船公司
        wharfCode: "", //码头代码
        wharf: "", //码头名称
        portType: "1", //港口，0：宁波，1：上海
        vessel: "", //船名
        voyage: "", //航次
        ctnSocMark: "N", //货主箱标志，Y=货主箱N=非货主箱
        isSendSync: 0, //是否和舱单同时发送，0：否，1：是
      },
      rangeNumber: 10,
      hideVesselAndVoyage: false,
      checkStatus:1,//1：保存到待发送，4：标记为已复核
    }
  },
  methods: {
    inputFilter(value, key) {
      if (value) {
        this.form[key] = setRightForrmat(value);
      }
    },
    inputFilter2(value, key) {
      if (value) {
        this.form[key] = setRightForrmatPlus(value);
      }
    },
    //下拉框滚动加载
    loadMore() {
      return () => this.rangeNumber += 10;
    },
    //下拉框失去焦点重置
    blur() {
      this.rangeNumber = 10;
    },
    billsVisible(value) {
      let isVisble = /^(0+)|[^\d]+/g.test(value);
      if (isVisble || !value) {
        this.form.bills = "";
      } else {
        this.form.bills = Number(value);
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.baseForm.validate((valid) => {
          if (!valid) {
            return reject('请检查VGM基本信息必填项')
          } else {
            return resolve();
          }
        })
      });
    },
    setWharf(code) {
      if (code) {
        const wharf = this.wharfList.filter(item => item.value === code);
        this.form.wharf = wharf && wharf[0].label ? wharf[0].label : '';
        this.form.otherCode = wharf && wharf[0].otherCode ? wharf[0].otherCode : '';
      }
    },
    async checkWharf(status=1) {
      this.checkStatus = status;
      return new Promise(async (resolve, reject) => {
        await this.getWharfInfo(1).then(() => {
          return resolve();
        }).catch((err) => {
          return reject("请检查码头信息");
        })
      });
    },

    //码头列表
    getWharfList() {
      getShWharfList().then(({data}) => {
        this.wharfList = data.data.list.map(x => {
          return {
            id: x.id,
            label: x.nickName ? `（${x.nickName}）${x.name}` : x.name,
            value: x.code,
            otherCode: x.otherCode
          }
        });
      });
    },
    selectShip(ship) {
      this.$emit("changeTableShowProps", this.handleShipAddressNeed(ship));
    },
    //触发舱单界面自动生成VGM数据
    autoVGM() {
      this.$emit("autoVGM");
    },
    removeVGM() {
      this.$emit("removeVGM");
    },
    /**
     * 查询船名航次是否存在
     * @returns {boolean}
     * type:0：查码头，1：校验码头-不一致弹窗提示
     */
    async getWharfInfo(type = 0) {
      // console.log("type===", type)
      // console.log("wharfCode===", this.form.wharfCode)
      if (!this.form.vessel || !this.form.voyage) {
        this.$message.error("请输入船名和航次")
        return false;
      } else if (this.form.vessel.includes('V.') || this.form.voyage.includes('v.')) {
        this.$message.error("航次不能有V.")
        return false;
      }

      const matchedWharf = this.form.wharfCode?this.wharfList.find(item => item.value.toUpperCase() === this.form.wharfCode.toUpperCase()):'';

      const param = {
        vessels: this.form.vessel,
        voyage: this.form.voyage,
        wharf: this.form.wharf,
        wharfCode: this.form.wharfCode,
        otherCode: matchedWharf ? matchedWharf.otherCode.toUpperCase() : "",
      }
      this.getWharfInfoLoading = true;
      await checkVesselsVoyageInfo(param).then((res) => {
        if (res.data.status) {
          if (type === 0) {
            this.$refs.GetWharfDialog.init(param, res.data.data);
          } else {
            // console.log(res.data.data.length)
            // console.log(param.otherCode)
            // console.log(res.data.data[0].wharfCode.toUpperCase())
            if (res.data.data.length === 1 && param.otherCode === res.data.data[0].wharfCode.toUpperCase()) {
              return Promise.resolve();
            } else {
              this.$refs.GetWharfDialog.init(param, res.data.data, type);
              return Promise.reject("请检查");
            }

          }
        }
      }).finally(() => {
        this.getWharfInfoLoading = false;
      })
    },
    changeWharf(entity) {
      let wharfCode = entity.wharfCode.toLowerCase()
      const matchedWharf = this.wharfList.find(item => item.otherCode === wharfCode);
      this.form.wharfCode = matchedWharf && matchedWharf.value;
      this.form.otherCode = matchedWharf && matchedWharf.otherCode;
      this.form.wharf = matchedWharf && matchedWharf.label;
      this.$forceUpdate();
    },
    wharfSure() {
      this.$emit("wharfSure",this.checkStatus);
    }
  }
  ,
  mounted() {
    this.getWharfList();
  }
  ,
//自定义指令
  directives: {
    'el-select-loadmore':
        (el, binding) => {
          // 获取element-ui定义好的scroll盒子
          const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
          if (SELECTWRAP_DOM) {
            SELECTWRAP_DOM.addEventListener("scroll", function () {
              /**
               * scrollHeight 获取元素内容高度(只读)
               * scrollTop 获取或者设置元素的偏移值,
               *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
               * clientHeight 读取元素的可见高度(只读)
               * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
               * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
               */
              const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
              if (condition) binding.value();
            });
          }
        },
  }
}
</script>


<style scoped lang="stylus">
.the_title {
  margin-top: 2rem;
  user-select: none;
  cursor: pointer;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin-bottom: 1rem;
}

.el-form-item {
  //margin-bottom: 1rem;
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: var(--BLACK-333) !important;
}

/deep/ .el-input__inner {
  font-family: 'Mono-regular' !important;
}

.the_base {
  width: 23rem;
}

/deep/ .delete_span {
  color: red;
}
</style>
