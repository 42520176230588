// 支付模块
// 发票信息设置
export const ticketInfoSetting = [
    {
        label: "纳税人识别号",
        name: "taxIdNumber",
    },
    {
        label: "税务登记地址",
        name: "registerAddress",
    },
    {
        label: "税务登记电话",
        name: "registerPhone",
    },
    {
        label: "税务开户银行",
        name: "bankDeposit",
    },
    {
        label: "税务开户银行账号",
        name: "bankAccount",
    },
    {
        label: "接受电子发票邮箱",
        name: "contactEmail",
    },
    {
        label: "接受电子发票联系人",
        name: "contactName",
    },
    {
        label: "接受电子发票联系人电话",
        name: "contactPhone",
    },
    // {
    //     label: "发票收件人",
    //     name: "addressee"
    // },
    // {
    //     label: "联系电话",
    //     name: "contactPhone"
    // },
    // {
    //     label: "详细地址",
    //     name: "contactAddress"
    // }
];
// 发票状态
export const ticketStatus = [
    {
        type: "info",
        name: "未开票",
    },
    {
        type: "error",
        name: "开票中",
    },
    {
        type: "warning",
        name: "已开票",
    },
    {
        type: "success",
        name: "已寄出",
    },
];

// 发票种类
export const ticketClass = [
    {
        type: "primary",
        name: "增值税普通发票（电子发票）",
    },
    {
        type: "danger",
        name: "增值税专用发票（纸质发票）",
    },
];

// 发票详情(发票信息)
export const ticketDetailTicketInfo = [
    { label: "发票类别", prop: "invoiceType" },
    { label: "开票金额", prop: "amnount" },
    { label: "企业名称", prop: "companyName" },
    { label: "纳税人识别号", prop: "taxIdNumber" },
    {
        label: "税务登记地址",
        prop: "registerAddress",
        width: "66.6%",
    },
    {
        label: "税务登记电话",
        prop: "registerPhone",
    },
    {
        label: "税务开户银行",
        prop: "bankDeposit",
    },
    {
        label: "税务开户银行账号",
        prop: "bankAccount",
    },
];
// 发票详情(快递信息)
export const ticketDetailDeliverInfo = [
    { label: "快递号", prop: "expressNumber" },
    { label: "收件人", prop: "addressee" },
    { label: "联系电话", prop: "contactPhone" },
    { label: "详细地址", prop: "contactAddress", width: "100%" },
];

// 支付方式
export const payMethods = [
    {
        img: require("@/assets/img/wx.png"),
        name: "WXPAY",
        cName: "微信支付",
    },
    {
        img: require("@/assets/img/zfb.png"),
        name: "ALIPAY",
        cName: "支付宝支付",
    },
];

// 账户
export const payAccount = [
    {
        type: 0,
        img: require("@/assets/img/acUser.png"),
        name: "accountUser",
        cName: "个人账户余额",
    },
    {
        type: 1,
        img: require("@/assets/img/acCompany.jpg"),
        name: "accountCompany",
        cName: "企业账户余额",
    },
];

// 消费记录账户类别
export const accountTypeSelect = [
    {
        value: 0,
        label: "个人账户",
    },
    {
        value: 1,
        label: "企业账户",
    },
    {
        value: null,
        label: "全部",
    },
];

//消费记录 变动方式
export const waySelect = [
    {
        value: "RECHARGE",
        label: "充值",
    },
    {
        value: "GIVE",
        label: "赠送",
    },
    {
        value: "CONSUME",
        label: "消费",
    },
    {
        value: "REFUND",
        label: "退款",
    },
    {
        value: "FREE_GIVE",
        label: "免费赠送",
    },
    {
        value: "FREE_CONSUME",
        label: "免费消费",
    },
    {
        value: null,
        label: "全部",
    },
];

// 消费记录table
export const consumeTableList = [
    {
        label: "订单编号",
        width: 190,
        prop: "orderNo",
    },
    {
        label: "充值账户",
        prop: "accountType",
    },
    {
        prop: "amount",
        label: "变动金额(火币)",
        // columnType: true
    },
    {
        prop: "way",
        label: "变动原因",
        // columnType: true
    },
    {
        prop: "balance",
        label: "账户余额",
        // columnType: true
    },
    {
        prop: "nbFreeBalance",
        label: "账户余额(免)",
    },
    {
        prop: "origin",
        label: "变动渠道",
    },
    {
        prop: "category",
        label: "消费类目",
        // columnType: true
    },
    {
        prop: "remarks",
        label: "订单备注",
    },
    {
        prop: "updateTime",
        label: "变动时间",
        width: 250,
    },
];
// 消费记录filter
export const consumeTableFilter = [
    {
        type: "datePickRanger",
        name: "消费时间",
        prop: "date",
        options: { query: ["startTime", "endTime"] },
        default: [],
    },
    {
        type: "select",
        name: "账户类别",
        prop: "accountType",
        default: null,
        options: accountTypeSelect,
    },
    {
        type: "select",
        name: "变动方式",
        prop: "way",
        default: null,
        options: waySelect,
    },
];

// 金额比例
export const payRate = 100;
