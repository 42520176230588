<template>
  <!--  <el-drawer-->
  <!--      :visible.sync="drawer"-->
  <!--      :direction="direction"-->
  <!--      :destroy-on-close="true"-->
  <!--  >-->
  <!--    <div class="container">-->
  <!--      <p class="title">{{ detail.subject }}</p>-->
  <!--      <div v-html="detail.emailContent" class="content">-->
  <!--      </div>-->
  <!--      <p v-if="detail.clientFileUrl" class="update_span" @click="downloadData(detail.clientFileUrl)">下载附件</p>-->
  <!--    </div>-->
  <!--  </el-drawer>-->
  <!--  <el-dialog-->
  <!--      v-draggable-->
  <!--      :modal="false"-->
  <!--      :modal-append-to-body="false"-->
  <!--      :close-on-click-modal="false"-->
  <!--      :visible.sync="drawer"-->
  <!--      style="z-index: 9999"-->
  <!--      @close="closeDialog">-->
  <div class="fixToolBar_wrap" v-drag v-if="drawer">
    <p class="header"><i class="el-icon-close" @click="closeDialog"></i></p>
    <div class="container">
      <p class="title">{{ detail.subject }}</p>
      <div v-html="detail.emailContent" class="content">
      </div>
      <p v-if="detail.clientFileUrl" class="update_span" @click="downloadData(detail.clientFileUrl)">下载附件</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      direction: 'ltr',
      detail: {},
    }
  },
  methods: {
    init(info) {
      this.detail = info;
      this.drawer = true;
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    closeDialog() {
      this.drawer = false;
    },
    //下载客户资料
    downloadData(url) {
      const link = document.createElement('a');
      link.href = url;
      const fileName = url.substring(url.lastIndexOf('/') + 1);
      link.download = fileName; // 设置下载的文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  }
}
</script>


<style scoped lang="stylus">
.container {
  background: #fff;
  padding: 1rem;
  height: 100%;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
}

.title {
  font-size: 2rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 1rem;
}

.content {
}

.update_span {
  font-size: 1.6rem;
  cursor: pointer;
  color: blue;
  display: inline-block;
}

.header {
  margin: 0;
  cursor: move;
  box-sizing: border-box;
  padding: 1rem;
  background: var(--NB-RED-THIRD);
  width: 100%;
  color: white;
  font-size: 2rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  justify-content: right;

  i {
    cursor: pointer;
  }
}

.fixToolBar_wrap {
  position: fixed;
  bottom: 3%;
  left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;
  border-radius: 4px;
  width: 30vw;
  max-height: 40vh;
  min-height: 30vh;
  box-sizing: border-box;
  background: #ffffff;
}

</style>
