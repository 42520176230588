<template>
    <div class="the_table">
        <el-table
            ref="multipleTable"
            border
            stripe
            :data="tableData"
            tooltip-effect="dark"
            :max-height="tableHeight"
            v-loading="dataLoading"
            :cell-class-name="remarkCellClass"
            @selection-change="handleSelectionChange"
            :header-cell-style="{
                background: '#d4d9e1',
                color: '#606278',
                'text-align': 'center',
            }"
            :cell-style="{ 'text-align': 'center', padding: '0px' }"
        >
            <slot name="first">
                <el-table-column
                    type="selection"
                    width="55"
                    fixed="left"
                ></el-table-column>
            </slot>
            <el-table-column
                type="index"
                fixed="left"
                width="50"
            ></el-table-column>
            <el-table-column
                v-if="[0].includes(type)"
                fixed="left"
                label="校验状态"
                width="100"
            >
                <template slot-scope="scope">
                    <img
                        :src="scope.row.validation === 0 ? errorImg : tickImg"
                        class="tick_img"
                    />
                </template>
            </el-table-column>
            <el-table-column
                v-for="item in tableHeader"
                :key="item.prop"
                :prop="item.prop"
                :label="item.label"
                align="left"
                :width="item.width ? item.width : 'auto'"
                :fixed="item.fixed"
            >
                <template slot-scope="scope">
                    <span v-if="item.prop === 'billNo'" :class="item.prop">
                        {{ scope.row[item.prop] }}
<!--                        <span-->
<!--                            v-if="scope.row.createUserId != loginUserId"-->
<!--                            style="color: black; margin-left: 10px"-->
<!--                        >-->
<!--                            {{ `(${scope.row.createUserName})` }}-->
<!--                        </span>-->
                        <img
                            src="@/assets/icon/copy.svg"
                            v-clipboard:copy="scope.row.billNo"
                            v-clipboard:success="copySucc"
                        />
                    </span>
                    <span v-else-if="item.prop === 'completionstatus'">
                        {{
                            scope.row[item.prop] === 2
                                ? "已发送"
                                : scope.row[item.prop] === -2
                                ? "已删改"
                                : scope.row[item.prop] === -3
                                ? "柜台删单"
                                : scope.row[item.prop] === 1
                                ? "待发送"
                                : scope.row[item.prop] === -1
                                ? "垃圾箱"
                                : "草稿箱"
                        }}
                    </span>
                    <span
                        v-else-if="item.prop === 'vgmReceiptMsg'"
                        :class="item.prop"
                    >
                        <img
                            v-if="scope.row[item.prop]"
                            :src="
                                scope.row['vgmReceiptStatus'] == 1 ? receiptSuccessImg
                                    : scope.row['vgmReceiptStatus'] == null ? receiptWaitImg
                                    : receiptFailImg
                            "
                        />
                        {{ scope.row[item.prop] }}
                    </span>
                    <span
                        v-else-if="item.prop === 'remark'"
                        :class="[
                            item.prop,
                            scope.row[item.prop]
                                ? 'table_link_text_bold_black'
                                : '',
                        ]"
                        @click="
                            !scope.row[item.prop] ? addRemark(scope.row) : ''
                        "
                        class="table_link_text_bold"
                    >
                        <i
                            @click="addRemark(scope.row)"
                            v-if="scope.row[item.prop]"
                            class="el-icon-edit"
                        ></i>
                        {{
                            scope.row[item.prop]
                                ? scope.row[item.prop]
                                : "添加备注"
                        }}
                    </span>
                    <template v-else-if="item.prop === 'ctnNoList'">
                        <div
                            class="good_ctnno"
                            v-for="ctn in scope.row.goods"
                            :key="ctn.id"
                        >
                            {{ ctn.ctnNo }}
                        </div>
                    </template>
                    <template v-else-if="item.prop === 'vgmGrossWeightList'">
                        <div
                            class="good_ctnno"
                            v-for="ctn in scope.row.goods"
                            :key="ctn.id"
                        >
                            {{ ctn.vgmGrossWeight }}
                        </div>
                    </template>
                    <span
                        v-else-if="item.prop === 'operator'"
                        class="operator"
                        @click="operator('getHistory', scope.row)"
                    >
                        {{
                            scope.row.updateUserName
                                ? scope.row.updateUserName
                                : scope.row.createUserName
                        }}
                    </span>
                    <span v-else :class="item.prop">
                        {{ scope.row[item.prop] }}
                    </span>
                </template>
            </el-table-column>
            <slot name="todo">
                <el-table-column fixed="right" label="操作" width="160">
                    <template slot-scope="scope">
                        <div>
                            <span
                                class="update_span"
                                v-if="[0, 1, 2, 3].includes(type)"
                                @click="operator('copy', scope.row)"
                            >
                                复制
                            </span>
                            <span
                                class="update_span"
                                v-if="[0, 1].includes(type)"
                                @click="operator('edit', scope.row)"
                            >
                                修改
                            </span>
                            <span
                                class="update_span"
                                v-if="[2].includes(type)"
                                @click="operator('look', scope.row)"
                            >
                                查看
                            </span>
                            <span
                                class="delete_span"
                                v-if="[0, 1, 3].includes(type)"
                                @click="showDelete(scope.row)"
                            >
                                删除
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <!--        <template v-if="alreadySend">-->
                <!--          <el-table-column fixed="right" label="操作" width="160">-->
                <!--            <template slot-scope="scope">-->
                <!--              <span class="look_span" @click="operator('look',scope.row)">查看</span>-->
                <!--              <span class="update_span" @click="operator('edit',scope.row)">修改</span>-->
                <!--              <span class="delete_span" @click="toReturnDelete(scope.row)">删除</span>-->
                <!--            </template>-->
                <!--          </el-table-column>-->
                <!--        </template>-->
            </slot>
        </el-table>
        <div class="pagination_div">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total,sizes,prev, pager, next"
                :total="totalCount"
                :page-count="totalPage"
                :page-sizes="[50, 100, 200]"
                :page-size="pageSize"
                :current-page="currPage"
            ></el-pagination>
        </div>
        <DeleteVGMDialog
            ref="DeleteVGMDialog"
            @refresh="refreshList"
        ></DeleteVGMDialog>
        <LookDetailDialog
            ref="LookDetailDialog"
            v-if="lookDetailDialog"
        ></LookDetailDialog>
        <VgmHistoryDialog ref="VgmHistoryDialog"></VgmHistoryDialog>
        <!-- 弹窗, 修改备注 -->
        <remark-box-dialog
            :show-tab="showTab"
            v-if="remarkDialogVisible"
            ref="RemarkBoxDialog"
            @refreshDataList="refreshList"
        ></remark-box-dialog>
    </div>
</template>

<script>
import LookDetailDialog from "@/views/VGM/components/LookDetailDialog.vue";
import ShanghaiChatDialog from "./ShanghaiChatDialog.vue";
import VgmHistoryDialog from "./VgmHistoryDialog";
import DeleteVGMDialog from "@/views/VGM/components/DeleteVGMDialog.vue";
import RemarkBoxDialog from "@/views/ShanghaiManifest/components/ManifestRemarkDialog.vue";
import tickImg from "@/assets/img/green.png";
import errorImg from "@/assets/img/red.png";
import ReceiptSuccessImg from "@/assets/img/green.png";
import ReceiptFailImg from "@/assets/img/red.png";
import ReceiptWaitImg from "@/assets/img/wait.png";

export default {
    components: {
        RemarkBoxDialog,
        LookDetailDialog,
        DeleteVGMDialog,
        ShanghaiChatDialog,
        VgmHistoryDialog,
    },
    props: {
        tableHeader: {
            //列表标题
            type: Array,
            default: () => [],
        },
        tableData: {
            //列表数据
            type: Array,
            default: () => [],
        },
        type: {
            type: Number,
            default: 0, //0:草稿箱，1：待发送，2：已发送
        },
        currPage: {
            type: Number,
            default: 1,
        },
        totalPage: {
            type: Number,
            default: 10,
        },
        pageSize: {
            type: Number,
            default: 50,
        },
        totalCount: {
            type: Number,
            default: 10,
        },
        dataLoading: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        dataLoading(newVal) {
            return newVal;
        },
    },
    data() {
        return {
            receiptSuccessImg: ReceiptSuccessImg,
            receiptFailImg: ReceiptFailImg,
            receiptWaitImg: ReceiptWaitImg,
            tickImg: tickImg,
            errorImg: errorImg,
            remarkDialogVisible: false,
            showTab: "VGM",
            deleteDialogVisible1: false,
            isDraft: false,
            isWaitSend: false,
            isAlreadySend: false,
            lookDetailDialog: false,
            waitSend: false,
            alreadySend: false,
            AllPackage: 0,
            AllWeight: 0,
            AllVolume: 0,
            DeletetableData: [], //删除的表格数据
            DeleteID: "",
            deleteDialogVisible: false,
            UpdateManifest: false,
            multipleSelection: [], //多选中的信息
            goodsData: [],
            loginUserId: "",
            tableHeight: 500,
            topMsgBarHeight: 80,
        };
    },
    computed: {},
    mounted() {
        this.loginUserId = this.$store.state.user.userId;
        this.getTableHeight();
        //挂载window.onresize事件(动态设置table高度)
        let _this = this;
        window.onresize = () => {
            if (_this.resizeFlag) {
                clearTimeout(_this.resizeFlag);
            }
            _this.resizeFlag = setTimeout(() => {
                _this.getTableHeight();
                _this.resizeFlag = null;
            }, 100);
        };
    },
    methods: {
        //计算table高度(动态设置table高度)
        getTableHeight() {
            this.topMsgBarHeight =
                document.getElementById("VGMSearchBar").offsetHeight + 200;
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - this.topMsgBarHeight;
            });
        },
        copySucc() {
            this.$message.success("复制成功");
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.$emit("fromChild", this.multipleSelection);
        },
        operator(type, item) {
            //edit,delete,copy
            if (type === "look") {
                this.lookDetailDialog = true;
                this.$nextTick(() => {
                    this.$refs.LookDetailDialog.init(item);
                });
            }
            //如果是复制的话，id设置为空
            if (type === "copy") {
                item.copy = true;
                console.log("复制数据" + JSON.stringify(item));
            }
            if (type === "getHistory") {
                this.showVgmHistoryDialog(item);
            }
            this.$emit(type, item);
        },
        agentDelete(id) {},
        refreshList() {
            this.$emit("refresh");
        },
        showDelete(item) {
            //删除舱单
            const deleteData = [];
            deleteData.push(item);
            this.$nextTick(() => {
                this.$refs.DeleteVGMDialog.init(deleteData);
            });
        },
        lookit(item) {
            //查看详情
            this.lookDetailDialog = true;
            this.$nextTick(() => {
                this.$refs.LookDetailDialog.init(item);
            });
        },
        handleSizeChange(val) {
            this.$emit("pageSizeChange", val);
        },
        handleCurrentChange(val) {
            this.$emit("nextPage", val);
        },
        //显示VGM操作历史
        showVgmHistoryDialog(item) {
            this.$refs.VgmHistoryDialog.init(item);
        },
        //添加备注
        addRemark(item = this.rows) {
            this.remarkDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.RemarkBoxDialog.init({
                    id: item.id,
                    blno: item.billNo,
                    remark: item.remark,
                });
            });
        },
        remarkCellClass({ row, column, rowIndex, columnIndex }) {
            if (column.property) {
                // if (column.property === "remark") {
                //   if (row.sendStatus === 1) {
                //     return "green_cell";
                //   }
                //   if (row.sendStatus === 2) {
                //     return "red_cell";
                //   }
                // }
                // if (column.property === "vgmReceiptMsg") {
                //   if (row.vgmReceiptStatus === '1') {
                //     return "green_cell";
                //   }
                //   if (row.vgmReceiptStatus === '2') {
                //     return "red_cell";
                //   }
                // }
                if (
                    ["ctnNoList", "vgmGrossWeightList"].includes(
                        column.property,
                    )
                ) {
                    return "goods_cell";
                }
            }
        },
    },
};
</script>

<style lang="stylus" scoped>

/deep/ .red_cell {
  //background-color: var(--RED-F56C6C) !important;
  color: var(--BLACK-2c2c2c);
}

/deep/ .green_cell {
  background-color: var(--GREEN-03aa53) !important;
  color: var(--BLACK-2c2c2c);
}

.update_span {
  color: blue;
  display: inline-block;
  margin-right: 1.5rem;
}

.delete_span {
  color: red;
}

.the_table {
  margin-top: 1rem;
}

.pagination_div {
  text-align: center;
  margin: 1rem;
}

.look_span,
.update_span,
.delete_span {
  cursor: pointer;
}

.look_span {
  color: green;
  display: inline-block;
  margin-right: 1.5rem;
}

.pagination_div {
  text-align: center;
  margin: 1rem auto 0 !important;
}


.vesselname {
  font-weight: bold;
}

.total {
  text-align: center;
  display: block;
}

.table_link_text_bold {
  color: var(--BLUE-5493f4);
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }
}

.billNo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  color: var(--BLUE-3366FF);

  img {
    cursor: pointer;
  }
}

.table_link_text_bold_black {
  color: var(--BLACK-333);
  cursor: text;
  user-select: auto;

  i {
    cursor: pointer;
    user-select: none;
  }
}

.success {
  color: var(--GREEN-1E9855);
  font-weight: bold
}

.el-icon-edit {
  margin-right: 0.2rem;
}

/deep/ .goods_cell {
  .cell {
    padding: 0 !important;
  }
}

.vgmReceiptMsg {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--BLACK-2c2c2c);
  //font-weight: bold;

  img {
    position: absolute;
    left: 1rem;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
  }
}

.good_ctnno {
  margin: 0;
}

.good_ctnno:not(:last-child) {
  border-bottom: 1px solid var(--GRAY-dee3ed);
  padding-bottom: 6px;
}

.good_ctnno:not(:first-child) {
  padding-top: 6px;
}

/deep/ .el-table .el-table__cell {
  padding: 6px 0 !important;
}

.tick_img {
  width: 25px;
  height: 25px;
  margin: auto;
  display: flex;

}
.operator{
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;
}
div{
  font-family: 'Mono-regular';
}
p{
  font-family: 'Mono-regular';
}
span{
  font-family: 'Mono-regular';
}
</style>
