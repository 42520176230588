export default [
    {
        path: "/sh_manifest",
        component: () => import("@/views/ShanghaiManifest/Draft"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path: "/sh_manifest/all",
        component: () => import("@/views/ShanghaiManifest/AllManifest"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path: "/sh_manifest/new",
        name: "CreateManifest",
        component: () => import("@/views/ShanghaiManifest/components/AddManifestDialog"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path: "/sh_manifest/wait",
        component: () => import("@/views/ShanghaiManifest/WaitSend"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path: "/sh_manifest/already",
        component: () => import("@/views/ShanghaiManifest/AlreadySend"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path: "/sh_manifest/counter",
        component: () => import("@/views/ShanghaiManifest/CounterDelete"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path: "/sh_manifest/dustbin",
        component: () => import("@/views/ShanghaiManifest/Dustbin"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path: "/shanghai_sft_revice",
        component: () => import("@/views/ShanghaiManifest/Shanghai_R"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path: "/shanghai_sft_send",
        component: () => import("@/views/ShanghaiManifest/Shanghai_S"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path: "/shanghai_sft_notice",
        component: () => import("@/views/ShanghaiManifest/Shanghai_N"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path: "/shanghai_sft_rsn",
        component: () => import("@/views/ShanghaiManifest/Shanghai_RSN"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path: "/sh_manifest/uandd",
        component: () => import("@/views/ShanghaiManifest/UpdateDelete"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path: "/sh_manifest/guide/agreement",
        component: () => import("@/views/ShanghaiManifest/guide/Agreement.vue"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path: "/sh_manifest/guide/book",
        component: () => import("@/views/ShanghaiManifest/guide/Book.vue"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path:"/sh_manifest/guide/delete",
        component: () => import("@/views/ShanghaiManifest/guide/Delete.vue"),
        meta: {activeMenu: '/sh_manifest'}
    },
    {
        path:"/sh_manifest/guide/business",
        component: () => import("@/views/ShanghaiManifest/guide/Business.vue"),
        meta: {activeMenu: '/sh_manifest'}
    }
];
