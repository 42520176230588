<template>
  <div>
    <el-dialog
        title="船司船代检验结果"
        :visible.sync="dialogVisible"
        width="52%"
        append-to-body
        center
    >
      <div
          v-if="!data_list||data_list.length===0||!(inputData.shipCompanyValue===data_list[0].shipCompanyValue&&inputData.shipAgencyValue === data_list[0].shipAgencyValue)"
      >
        <p class="tip_span_title">
          请检查船司船代！</p>
        <p class="tip_span">
          请检查是否填写有误、是否有多余空格、是否发送过早或者港区更新不及时，确认无误可直接保存</p>
      </div>
      <el-table :data="data_list" style="width: 100%" stripe border
                max-height="500"
                :header-cell-style="{background: '#d4d9e1',color: '#606278'}"
      >
        <el-table-column
            v-for="item in tableHeaders"
            :prop="item.prop"
            :label="item.label"
            :width="item.width?item.width:'auto'"
            :key="item.prop"
        >
          <template slot-scope="scope">
            <div v-if="item.prop === 'shipCompany'">
              <p
                  :class="inputData.shipCompanyValue&&inputData.shipCompanyValue===scope.row.shipCompanyValue?'':'sys_voyage_span'">
                {{
                  (inputData.shipCompanyValue === scope.row.shipCompanyValue ? '' : '推荐船司：') + scope.row.shipCompany
                }}
              </p>
              <p v-if="inputData.shipCompanyValue!==scope.row.shipCompanyValue" class="user_voyage_span">
                {{ '您的船司：' + inputData.shipCompanyValue }}</p>

            </div>
            <div v-else-if="item.prop === 'shipAgency'">
              <p
                  :class="scope.row.shipAgencyValue ? inputData.shipAgencyValue===scope.row.shipAgencyValue?'':'sys_voyage_span':''">
                {{
                  (scope.row.shipAgencyValue ? inputData.shipAgencyValue === scope.row.shipAgencyValue ? '' : '推荐船代：' : '') + scope.row.shipAgency
                }}
              </p>
              <p v-if="inputData.shipAgencyValue&&inputData.shipAgencyValue!==scope.row.shipAgencyValue"
                 class="user_voyage_span">
                您的船代：{{ inputData.shipAgencyValue.split('|')[0] }}</p>

            </div>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <slot name="todo">
          <el-table-column fixed="right" label="操作" width="80">
            <template slot-scope="scope">
              <div v-if="data_list&&data_list[0].shipAgencyValue">
                <span class="update_span" @click="operator('select',scope.row)">选择</span>
              </div>
            </template>
          </el-table-column>
        </slot>
      </el-table>
      <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="operator('sure')"
                         v-if="!(data_list||data_list[0].shipAgencyValue)&&!showBtn||showBtn">确认无误</el-button>
                <el-button @click="operator('cancelBtn')">
                    取 消
                </el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import {eleDelete} from "@/api/shanghaiManifest";

export default {
  data() {
    return {
      tableHeaders: [
        {
          prop: "billNo",
          label: "主提单号",
          width: 180,
        },
        {
          prop: "vesselName",
          label: "船名",
          width: 180,
        },
        {
          prop: "voyage",
          label: "航次",
          width: 100,
        },
        {
          prop: "shipCompany",
          label: "船公司",
        },
        {
          prop: "shipAgency",
          label: "船代",
        },
        // {
        //   prop: "loadPort",
        //   label: "靠港码头",
        // },
        // {
        //   prop: "maxShipRoute",
        //   label: "大航线",
        // },
        // {
        //   prop: "minShipRoute",
        //   label: "小航线",
        // },
      ],
      dialogVisible: false,
      showBtn: false,
      data_list: [],
      inputData: {//用户输入的船司船代，用于和结果对比
        shipCompanyValue: '',
        shipAgencyValue: '',
      },
      status: 1,
    };
  },
  methods: {
    init(list, showSureBtn = false, userData, status = 1) {
      this.showBtn = showSureBtn;
      this.dialogVisible = true;
      this.data_list = list;
      this.status = status;
      if (userData) {
        console.log('userData', userData);
        this.inputData = userData;
      }
    },
    operator(type, item) {//edit,delete,copy
      if (type === 'sure') {
        this.$emit(type, this.status);
      } else {
        this.$emit(type, item);
      }
      this.dialogVisible = false;
    }
  },
};
</script>

<style lang="stylus" scoped>
.update_span {
  color: blue;
  cursor: pointer;
  margin-right: 1.5rem;
}

.sys_voyage_span {
  color: blue;
  font-size: 14px;
  margin: 0;
  //margin-right: 1.5rem;
}

.user_voyage_span {
  margin: 0;
  color: var(--RED-F21137);
  font-size: 14px;
  //margin-right: 1.5rem;
}

.tip_span_title {
  color: var(--RED-F21137);
  margin: 0 0 0.5rem 0;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1.5;
  text-align: center;
}

.tip_span {
  color: var(--RED-F21137);
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 2rem 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
}
</style>
