import { render, staticRenderFns } from "./ManifestRemarkDialog.vue?vue&type=template&id=199992a8&scoped=true"
import script from "./ManifestRemarkDialog.vue?vue&type=script&lang=js"
export * from "./ManifestRemarkDialog.vue?vue&type=script&lang=js"
import style0 from "./ManifestRemarkDialog.vue?vue&type=style&index=0&id=199992a8&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "199992a8",
  null
  
)

export default component.exports