<template>
  <div>
    <el-dialog
        title="舱单操作历记录"
        :visible.sync="dialogVisible"
        width="50%"
        append-to-body
        center
    >
      <p class="tip_span">
        提单号：{{ billItem ? billItem.billno : '' }}
      </p>
      <el-table :data="Data" style="width: 100%" stripe border
                max-height="500"
                ref="tableList"
                @expand-change="handleExpandChange"
                :header-cell-style="{background: '#d4d9e1',color: '#606278'}"
      >
        <el-table-column type="expand" v-if="checkPer(['shManifest:rocket'])">
          <template slot-scope="props">
            <div class="the_content">
              <BaseFormModule :item="detail.head" ref="baseForm" :is-look="true"></BaseFormModule>
              <ShipFormModule :item="detail.head" ref="shipForm" :is-look="true"></ShipFormModule>
              <SFTFormModule ref="consignerForm" :item="detail.contacts.filter(x=>x.type===0)[0]"
                             :type-val="{title:'发货人',code:0}"
                             :is-look="true"
              ></SFTFormModule>
              <SFTFormModule ref="consigneeForm" :item="detail.contacts.filter(x=>x.type===1)[0]"
                             :type-val="{title:'收货人',code:1}"
                             :is-look="true"
              ></SFTFormModule>
              <SFTFormModule ref="informersForm" :item="detail.contacts.filter(x=>x.type===2)[0]"
                             :type-val="{title:'通知人',code:2}"
                             :is-look="true"
              ></SFTFormModule>
              <div class="the_title">货物明细</div>
              <ManifestGoodsTable ref="ManifestGoodsTable" :item="detail.goods" :is-look="true"></ManifestGoodsTable>
              <div>
                <div class="the_title2">VGM</div>
                <AddVGMDialog
                    :is-manifest-sub="true"
                    :is-look="true"
                    ref="ManifestVGM"
                ></AddVGMDialog>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="operator"
            label="操作类型"
        ></el-table-column>
        <el-table-column
            prop="userName"
            width="100"
            label="操作人员"
        ></el-table-column>
        <el-table-column prop="createTime" label="时间" width="160">
        </el-table-column>
        <!--        <slot name="todo">-->
        <!--          <el-table-column fixed="right" label="操作" width="100">-->
        <!--            <template slot-scope="scope">-->
        <!--              <span class="operator_span">查看数据</span>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--        </slot>-->
      </el-table>
      <span slot="footer" class="dialog-footer">
                <el-button @click="onClose()">
                    关 闭
                </el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import {getManifestHistory} from "@/api/shanghaiManifest";
import BaseFormModule from "@/views/ShanghaiManifest/components/baseInfo/BaseFormModule.vue";
import ShipFormModule from "@/views/ShanghaiManifest/components/shipInfo/ShipFormModule.vue";
import SFTFormModule from "@/views/ShanghaiManifest/components/sft/SFTFormModule.vue";
import ManifestGoodsTable from "@/views/ShanghaiManifest/components/goods/ManifestGoodsTable.vue";
import AddVGMDialog from "@/views/VGM/components/AddVGMDialog.vue";
import bus from "@/utils/bus";

export default {
  components: {AddVGMDialog, SFTFormModule, ShipFormModule, BaseFormModule, ManifestGoodsTable},
  data() {
    return {
      dataLoading: false,
      dialogVisible: false,
      billItem: '',
      detail: {head: {}, contacts: [], goods: []},
      Data: [],
      // 展开数组
      expandRows: [],
    };
  },
  methods: {
    init(item) {
      this.dialogVisible = true;
      this.billItem = item;
      this.getHistoryData();
    },
    getHistoryData() {
      this.dataLoading = true;
      getManifestHistory({id: this.billItem.id})
          .then((res) => {
            if (res.data.status) {
              this.Data = res.data.list;
            }

          })
          .finally(() => {
            this.dataLoading = false;
          });
    },
    //将数据内容转化成json对象
    content2Json(content) {
      // console.log(JSON.parse(content));
      this.detail = JSON.parse(content);
      if (this.detail.vgm) {
        this.vgmDetail = this.detail.vgm;
      } else {
        this.vgmDetail = null;
      }
      this.$nextTick(() => {
        bus.$emit('refreshVGM', this.vgmDetail);
      })
    },
    onClose() {
      this.billItem = null;
      this.Data = [];
      this.dialogVisible = false;
    },
    // 展开改变触发函数,只展开一行
    handleExpandChange(row, expandedRows) {
      if (expandedRows.length > 1) {
        this.$refs.tableList.toggleRowExpansion(expandedRows[0])
      }
      this.$nextTick(() => {
        this.content2Json(row['content']);
      })
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="stylus" scoped>
.tip_span {
  text-align: center;
  color: var(--RED-F21137);
  margin: 0 0 1rem 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
}

.operator_span {
  cursor: pointer;
  text-align: center;
  color: var(--BLUE-409eff);
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 100%;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  padding: 0 1rem;
  line-height: 1.5;
  width: 100%;
}

.the_title {
  user-select: none;
  cursor: pointer;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin-bottom: 1rem;
}
.the_title2 {
  user-select: none;
  cursor: pointer;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin: 1rem 0;
}
</style>
