<template>
    <div :class="{ 'fixToolBar_wrap':true, 'collapse': !collapse }" v-drag>
        <!-- <el-popover placement="left" trigger="hover">
            <div class="bg-item" slot="reference">
                <img src="@/assets/icon/customer_service.svg" :draggable="false"/>
            </div>
            <div class="fixToolBar_content_wrap service_content">
                <div class="service_wrap">
                    <div class="service_title" style="margin-bottom: 10px">
                        <span>客服QQ</span>
                    </div>
                    <el-button
                        type="text"
                        size="small"
                        class="service_qq"
                        data-clipboard-text="2059310019"
                        @click="copyQQ"
                    >
                        2059310019
                    </el-button>
                    <el-button
                        type="text"
                        size="small"
                        class="service_qq"
                        data-clipboard-text="2121996356"
                        @click="copyQQ"
                        style="margin-left: 0"
                    >
                        2121996356
                    </el-button>
                </div>
            </div>
        </el-popover> -->
        <el-popover placement="left" trigger="hover">
            <div
                slot="reference"
                class="bg-item"
            >
                <img src="@/assets/icon/qq.svg" :draggable="false"/>
            </div>
            <div class="fixToolBar_content_wrap">
                <div class="img_title">QQ服务群</div>
                <img src="@/assets/qq_.png" alt="" />
            </div>
        </el-popover>
        <el-popover placement="left" trigger="hover">
            <div
                class="bg-item"
                slot="reference"
            >
                <img src="@/assets/icon/miniapp.svg" :draggable="false" />
            </div>
            <div class="fixToolBar_content_wrap">
                <div class="img_title">微信小程序</div>
                <img src="@/assets/wx_.png" alt="" />
            </div>
        </el-popover>
        <div
                class="bg-item switch-btn"
                slot="reference"
                @click="switchCollapse"
            >
                <img v-if="collapse" src="@/assets/icon/fix_toolbar.svg" :draggable="false"/>
                <img v-else src="@/assets/icon/fix_close_btn.svg" :draggable="false"/>
            </div>
    </div>
</template>
<script>
import Clipboard from "clipboard";
export default {
    data() { 
        return {
            collapse: true
        }
    },  
    mounted() {
        new Clipboard(".service_qq");
    },
    methods: {
        copyQQ() {
            this.$message.success("复制成功！");
        },
        switchCollapse() { 
            this.collapse = !this.collapse
        }
    },
};
</script>
<style lang="stylus" scoped>
@keyframes itemHeightGrow {
    from {
        height: 0px;
    }
    to{
        height: 20px;
    }
}
@keyframes rotate {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(180deg);
    }
}
.fixToolBar_wrap {
    position: fixed;
    bottom: 10%;
    right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
    z-index: 999;
    padding: 10px;
    border-radius: 20px;
    width: 40px;
    height: fit-content;
    box-sizing: border-box;
    background: #ffffff;
    &.collapse{
        .bg-item:not(.switch-btn){
            animation-duration: .5s;
            animation-name: itemHeightGrow;
            display: block;
        }
        .bg-item{
            padding: 10px 0;
        }
    }
    .bg-item{
        width: 20px;
        height: 20px;
        position:relative;
        display: none;
        &:hover{
            transform: scale(1.1);
        }
        &.switch-btn{
            display: block;
            &:hover{
                border: 0;
                animation-duration: .5s;
                animation-name: rotate;
                animation-direction: alternate;
            }
        }
        img {
            width: 100%;
            height: 100%;
            -webkit-user-drag: none;
        }
    }
}
.fixToolBar_content_wrap{
    width: 150px;
    height: 180px;
    position: relative;
    &.service_content{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100px;
    }
    .img_title {
        color: #333333;
        font-size: 14px;
        font-weight: bold;
        height: 30px;
        text-align: center;
        line-height: 30px;
    }
    img{
        width: 150px;
        height: 150px;
    }
    .service_wrap {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    }
    .service_title {
        display: flex;
        align-items:center;
        justify-content:center;
        color: #333333;
    }
    .service_title,.service_qq {
        font-size: 14px;
        font-weight: bold;
    }
}
</style>
