const state = {
    showUserId: "",
}

const mutations = {
    editShowUserId(state, id) {
        state.showUserId = id
    },
}

const actions = {

}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}
