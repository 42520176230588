<template>
  <ShipDialog
      :visible.sync="visible"
      title="发送资料"
      custom-class="shManifest-file-send-dialog"
      showTab="SH"
      :needImg="false"
      top="5vh"
      @close="close"
  >
    <el-form ref="extraFormRef" :rules="formRules" :model="extraInfo">
      <el-form-item>
        <span>快捷备注：</span>
        <div class="flex-container">
          <el-checkbox-group
              v-model="checkList"
              @change="updateRemark"
          >
            <el-checkbox
                v-for="remark in parentRemarkList"
                :label="remark"
                :key="remark"
            >
              {{ remark }}
            </el-checkbox>
          </el-checkbox-group>
          <el-button
              type="primary"
              size="small"
              class="editButtn"
              @click="startGetUserRemarkTags"
          >
            自定义备注
          </el-button>
        </div>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
            class="remarkText"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 8 }"
            placeholder="请输入内容"
            v-model="extraInfo.remark"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-tabs
        type="border-card"
        v-model="activeFileTab"
        v-if="mode === 'splice'"
    >
      <el-tab-pane
          v-for="item in Number(boxNumber)"
          :key="item"
          :name="item"
          :label="`拼箱${item}`"
      >
        <UploadFileAndFolder
            :ref="'uploadFileAndFolderRef' + item"
        ></UploadFileAndFolder>
      </el-tab-pane>
    </el-tabs>
    <UploadFileAndFolder
        ref="uploadFileAndFolderRef"
        v-else
    ></UploadFileAndFolder>
    <div class="flex-container">
      <el-checkbox
          v-model="continueSend"
          label="连续发送"
          border
          class="continueSend"
      ></el-checkbox>
      <DialogFooter
          confrimText="提交"
          cancelText="重置"
          showTab="SH"
          :loading="btnLoading"
          @cancel="handleReset"
          @confirm="handleSubmit"
      ></DialogFooter>
    </div>
    <el-dialog
        width="30%"
        title="自定义备注"
        :visible.sync="innerVisible"
        append-to-body
    >
      <div>

        <div class="flex-container">
          <el-input
              v-model="customRemark"
              size="medium"
              placeholder="请输入内容"
          ></el-input>
          <el-button type="primary" size="medium" @click="addFastRemark">
            添加快捷备注
          </el-button>
        </div>
        <el-table :data="remarkTagList" style="width: 100%" stripe border
                  :header-cell-style="{background: '#d4d9e1',color: '#606278'}"
        >
          <el-table-column
              v-for="item in tableHeaders"
              :prop="item.prop"
              :label="item.label"
              :width="item.width?item.width:'auto'"
              :key="item.prop"
          ></el-table-column>
          <slot name="todo">
            <el-table-column fixed="right" label="操作" width="80">
              <template slot-scope="scope">
                <div>
                  <span class="delete_span" @click="operator('delete',scope.row)">删除</span>
                </div>
              </template>
            </el-table-column>
          </slot>
        </el-table>
      </div>
    </el-dialog>
  </ShipDialog>
</template>

<script>
import JSZip, {files} from "jszip";
import {
  sendShManifestExtraInfo,
  addFastRemarkResquest,
} from "@/api/sh-manifest";
import moment from "moment";
import UploadFileAndFolder from "@/components/common/UploadFileAndFolder.vue";
import VesselSelect from "@/components/common/VesselSelect.vue";
import ShipAgentSelect from "@/components/common/ShipAgentSelect.vue";
import ShipCompanySelect from "@/components/common/ShipCompanySelect.vue";
import ShipDialog from "@/components/layout/ShipDialog.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import {deleteUserRemarkTags, getRemarkList, getUserRemarkTags} from "../../../api/shanghaiManifest";

const formRules = {
  scompany: [{required: true, message: "船公司必填"}],
};

export default {
  name: "SHManifestFileSend",
  props: {
    remarkList: {
      type: Array,
      default: () => ['发VGM', '等通知', '紧急', '危险品'],
    },
  },
  components: {
    UploadFileAndFolder,
    VesselSelect,
    ShipAgentSelect,
    ShipCompanySelect,
    ShipDialog,
    DialogFooter,
  },
  emits: ["confirm"],
  data() {
    return {
      mode: "whole", //whole-整柜、splice-拼箱
      size: "mini",
      formRules,
      visible: false,
      uuid: "",
      btnLoading: false,
      continueSend: false,
      innerVisible: false,
      customRemark: null,
      remarkTagList: [],//备注
      parentRemarkList: [],//备注
      localRemarkList: ['发VGM', '等通知', '紧急', '危险品'],
      checkList: [],
      extraInfo: {
        remark: "",
        files: null,
      },
      boxNumber: 1,
      activeFileTab: 1, // 分票选中的tab
      tableHeaders: [
        {
          prop: "label",
          label: "备注",
        },
      ],
    };
  },
  methods: {
    updateRemark() {
      const selectedRemarks = this.checkList.join(";");
      if (this.extraInfo.remark.trim() === "") {
        this.extraInfo.remark = selectedRemarks + " | ";
      } else {
        const cleanedRemark = this.extraInfo.remark.replace(/.*\|/, "");
        this.extraInfo.remark = selectedRemarks + " |" + cleanedRemark;
      }
    },
    getUserRemarks() {
      getRemarkList().then((res) => {
        if (res.data.status) {
          this.parentRemarkList = res.data.data;
          if (this.parentRemarkList.length > 0) {
            this.parentRemarkList = [...new Set([...this.localRemarkList, ...this.parentRemarkList])];
          }
        }
      })
    },
    startGetUserRemarkTags() {
      this.innerVisible = true
      this.getUserRemarkTags();
    },
    getUserRemarkTags() {
      const data = {keywords: ''}
      getUserRemarkTags(data).then((res) => {
        if (res.data.status) {
          this.remarkTagList = res.data.data.map(x => {
            return {
              id: x.id,
              label: x.remark,
              value: x.remark,
            }
          });
        }
      })
    },
    // submitExtraInfo() {
    //     const { billno, remark, ...restExtraInfo } = this.extraInfo;
    //     sendShManifestExtraInfo({
    //         billno: billno,
    //         subject: `(${
    //             this.mode === "splice" ? "拼箱" : "整柜"
    //         })${billno} 备注：${remark}`,
    //         uuid: this.uuid,
    //         ...restExtraInfo,
    //     })
    //         .then((res) => {
    //             this.$message.success("资料发送成功！");
    //             this.handleReset();
    //             this.$emit("confirm");
    //             this.close();
    //         })
    //         .finally(() => {
    //             this.btnLoading = false;
    //         });
    // },
    uploadFileFunc(zip, name) {
      zip.generateAsync({
        type: "blob",
      })
          .then((content) => {
            const fils = new File([content], name, {
              type: "zip",
            });
            this.extraInfo.files = fils;
            sendShManifestExtraInfo(this.extraInfo)
                .then(({data}) => {
                  if (data.status) {
                    this.$message.success("资料发送成功");
                    this.handleReset();
                    this.clearFrom();
                    this.$emit("confirm");
                    this.btnLoading = false;
                    if (this.continueSend == false) {
                      this.close();
                    }
                  } else {
                    const msg = data.message || "资料发送失败";
                    this.$message.error(msg);
                    this.btnLoading = false;
                  }
                })
                .catch(() => {
                  this.btnLoading = false;
                });
          })
          .catch(() => {
            this.btnLoading = false;
          });
    },
    // 压缩上传
    async zipAndUploadFull() {
      if (!this.$refs.uploadFileAndFolderRef.fileList.length) {
        this.$message.error("请上传文件！");
      } else {
        this.btnLoading = true;
        const zip =
            await this.$refs.uploadFileAndFolderRef.handleZipFileList(
                this.$refs.uploadFileAndFolderRef.fileList,
            );
        const name = `舱单(整柜)_${
            this.extraInfo.billno
        }_${moment().format("YYYYMMDDHHmmss")}.zip`;
        this.uploadFileFunc(zip, name);
      }
    },
    async zipAndUploadSplice() {
      const zipList = new JSZip();
      const fileLenArr = [];
      for (let i = 0; i < this.boxNumber; i++) {
        const boxSerialNum = i + 1;
        const curRefStr = `uploadFileAndFolderRef${boxSerialNum}`;
        const curBoxFileLen = this.$refs[curRefStr][0].fileList.length;
        if (!curBoxFileLen) {
          // 文件为空则不执行后面的逻辑
          fileLenArr.push(0);
          continue;
        } else {
          fileLenArr.push(curBoxFileLen);
        }
        let zipSingle = await this.$refs[
            curRefStr
            ][0].handleSingleFile();
        // 当前需要列表解析的zip
        const content = await zipSingle.generateAsync({
          type: "blob",
        });
        const fils = new File([content], `拼箱${boxSerialNum}.zip`, {
          type: "zip",
        });
        zipList.file(`拼箱${boxSerialNum}.zip`, fils);
      }
      const allBoxFileEmpty = fileLenArr.every((len) => len === 0);
      if (allBoxFileEmpty) {
        this.$message.error("请上传文件！");
      } else {
        this.btnLoading = true;
        const name = `舱单(拼箱)_${
            this.extraInfo.billno
        }_${moment().format("YYYYMMDDHHmmss")}.zip`;
        this.uploadFileFunc(zipList, name);
      }
    },
    handleSubmit() {
      const that = this;
      this.$refs.extraFormRef.validate((valid) => {
        if (valid && that.mode === "whole") {
          that.zipAndUploadFull();
        }
        if (valid && that.mode === "splice") {
          that.zipAndUploadSplice();
        }
      });
    },
    addFastRemark() {
      const customRemark = this.customRemark;
      if(!customRemark||!customRemark.length) {
        this.$message.error("请输入备注");
        return;
      }
      addFastRemarkResquest({remark: customRemark})
          .then((response) => {
            if (response.data.status) {
              this.$message({
                type: "success",
                message: "快捷备注添加成功",
              });
              this.customRemark = null;
            }
          })
          .finally(() => {
            this.getUserRemarkTags();
            this.getUserRemarks();
          });
    },
    handleReset() {
      this.$refs.extraFormRef.resetFields();
    },
    clearFrom() {
      this.checkList = [];
      this.$refs.uploadFileAndFolderRef.handleClearAll();
    },
    close() {
      this.handleReset();
      this.clearFrom();
      this.visible = false;
    },
    open(openMode) {
      if (openMode) {
        this.mode = openMode;
      }
      this.visible = true;
      this.getUserRemarks();
    },
    //删除用户备注标签
    deleteUserRemarkTag(id) {
      deleteUserRemarkTags({id: id}).then((res) => {
        if (res.data.status) {
          this.$message.success(res.data.data);
        }
      }).finally(() => {
        this.getUserRemarkTags();
        this.getUserRemarks();
      })
    },
    operator(type, item) {
      if (type === 'delete') {
        this.deleteUserRemarkTag(item.id);
      }
    },
  },
};
</script>

<style lang="stylus">
.shManifest-file-send-dialog {
  .el-input-number .el-input__inner {
    text-align: left;
  }

  .el-dialog__body {
    padding-top: 8px;
    padding-bottom: 24px;
  }
}

.remarkText {
  /deep/ .el-input__inner {
    height: 80px;
    font-size: 18px;
  }
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tags_dialog {

}

.editButtn {
  //margin-left: auto;
}

.continueSend {
  margin-top: 20px;
}
.delete_span{
  cursor: pointer;
  color:var(--RED-C11C20);
}
</style>
