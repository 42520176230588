<template>
  <div>
    <el-dialog
        title="检验提单号"
        :visible.sync="dialogVisible"
        width="40%"
        append-to-body
        center
    >
      <div>
        <p class="tip_span_title">
          错误的提单号将造成垃圾舱单！</p>
        <p class="tip_span_title">
          海关最高罚款5万元并取消报关资格！</p>
      </div>
      <p class="tip_span_title" v-if="!data_list||data_list.length===0">
        分提单号中至少要有一票和主提单号一致，请检查！</p>
      <div v-else>
        <p class="update_span">
          以下分提单号与主提单号：<span class="tip_span">{{ mainBillNo }}</span> 不符，请检查：</p>
        <el-table :data="data_list" style="width: 100%" stripe border
                  :header-cell-style="{background: '#d4d9e1',color: '#606278'}"
        >
          <el-table-column
              v-for="item in tableHeaders"
              :prop="item.prop"
              :label="item.label"
              :width="item.width?item.width:'auto'"
              :key="item.prop"
          ></el-table-column>
          <!--        <slot name="todo">-->
          <!--          <el-table-column fixed="right" label="操作">-->
          <!--            <template slot-scope="scope">-->
          <!--              <div>-->
          <!--                <span class="update_span" @click="operator('select',scope.row)">选择</span>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <!--        </slot>-->
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="operator('confirm',saveType)" v-if="confirmable" :disabled="countTime>0">
          确认无误<span v-if="countTime>0">（{{ countTime }}s）</span>
                </el-button>
                <el-button @click="operator('cancelBtn')">
                    取 消
                </el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>

export default {
  watch: {
    data_list(newVal) {
      return newVal;
    },
  },
  data() {
    return {
      tableHeaders: [
        {
          prop: "billno",
          label: "分提单号",
        },
        {
          prop: "ctnno",
          label: "箱号",
        },
        {
          prop: "sealno",
          label: "封号",
        },
      ],
      dialogVisible: false,
      confirmable: false,//是否可点击确认按钮
      countTime: 5,//如果显示确认无误按钮，倒计时5秒才可点击
      timer: '',//倒计时
      saveType: 1,//保存的状态，0：草稿，1：待发送，2：修改报文，3：重发报文，如果点确认无误则传回去
      data_list: [],
      mainBillNo: '',//主提单号
    };
  },
  methods: {
    init(billNo, data = [], canConfirm = false, saveType = 1) {
      this.countTime = 5;
      this.saveType = saveType;
      this.data_list = [];
      this.mainBillNo = billNo;
      for (var i = 0; i < data.length; i++) {
        this.data_list.push(data[i]);
      }
      this.dialogVisible = true;
      this.confirmable = canConfirm;
      if (this.confirmable) {
        this.timer = setInterval(() => {
          this.countTime--;
          if (this.countTime === 0) {
            clearInterval(this.timer);
          }
        }, 1000);
      }
    },
    operator(type, item) {//edit,delete,copy
      this.$emit(type, item);
      clearInterval(this.timer);
      this.dialogVisible = false;
    }
  },
};
</script>

<style lang="stylus" scoped>
.update_span {
  color: blue;
  cursor: pointer;
  margin-right: 1.5rem;
}

.tip_span {
  color: var(--RED-F21137);
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
}

.tip_span_title {
  color: var(--RED-F21137);
  margin: 0 0 0.5rem 0;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1.5;
  text-align: center;
}
</style>
